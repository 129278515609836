import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { getAtlasRequirements, setFilteredRequirements } from "store/reducers/extract/CurrentExtractionReducer";
import { unwrapResult } from "@reduxjs/toolkit";
import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import { useLocalStorage } from "hook/useLocalStorage";
import { StreamEvent, StreamEventType, StreamStopMsgData } from "types/Streaming/streamConfig";
import { HEARTBEAT } from "const-values/Stream";
import useTypewriter from "hook/useTypewriter";
import { useNotification } from "context/notificationContext";
import * as Sentry from "@sentry/react";
import { apiUrl } from "config/vultronConfig";

type AskAiVariables = {
  analysis_id: string;
  requirement_ids?: string[];
  user_query: string;
};
export type AskAiResponse = { response: string; requirement_ids: string[] };
let controller = new AbortController();
export const useAskAi = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id")?.toLocaleLowerCase();
  const extractionId = useAppSelector((store) => store.currentExtractionState.currentExtraction?.id);
  const [query, setQuery] = useState("");
  const requirementIds = useRef<string[]>([]);
  const [answer, setAnswer] = useState<string>("");
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { setToast } = useNotification();

  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0", "");
  const useAuth0Header = use_auth0 === true;

  const delayedAnswer = useTypewriter(answer, 6, isLoading);

  const abortConnection = useCallback(() => {
    controller.abort();
    controller = new AbortController();
    setIsLoading(false);
    setAnswer(delayedAnswer);
  }, [delayedAnswer]);

  const resetForm = useCallback(
    ({ resetQuery = true }: { resetQuery?: boolean; [otherKeys: string]: any } = {}) => {
      setIsError(false);
      setAnswer("");
      if (resetQuery) {
        setQuery("");
      }
      dispatch(setFilteredRequirements([]));
      requirementIds.current = [];
    },
    [dispatch]
  );

  const handleAskAi = useCallback(
    (variables: Omit<AskAiVariables, "analysis_id" | "user_query">) => {
      if (!extractionId || isLoading) return;
      setIsLoading(true);
      resetForm({ resetQuery: false });
      const params = { analysis_id: extractionId, user_query: query, ...variables };

      fetchEventSource(`${apiUrl}/autopilot/${projectId}/analysis/ai/stream`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Workspace: `Workspace ${workspace_id}`,
          Authorization: `Bearer ${localValue}`,
          "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
          Accept: "application/json",
        },
        body: JSON.stringify(params),
        signal: controller.signal,
        openWhenHidden: true,
        onmessage(msg) {
          if (msg.event === "FatalError") {
          }

          if (msg.event === StreamEvent.StreamRestart) {
            setIsError(false);
            setAnswer(msg.data || "");
            dispatch(setFilteredRequirements([]));
            requirementIds.current = [];
            return;
          }

          if (msg.event === StreamEvent.StreamStop) {
            abortConnection();

            try {
              const parsedData = JSON.parse(msg.data) as StreamStopMsgData;
              if (parsedData.type === StreamEventType.Repetition) {
                setIsError(true);
                setAnswer("");
                dispatch(setFilteredRequirements([]));
                requirementIds.current = [];
              }
              if (parsedData.reason) {
                setToast.error({
                  msg: parsedData.reason,
                });
              }
            } catch {}
            return;
          }
          if (msg?.data === HEARTBEAT) return;

          if (!msg.event) {
            if (msg.data?.length) setAnswer((prev) => `${prev}${msg.data}`);
            if (!msg.data?.length) setAnswer((prev) => `${prev}\n`);
          } else if (msg.event === StreamEvent.Payload) {
            try {
              const { ids }: Record<"ids", string[]> = JSON.parse(msg.data);
              requirementIds.current = ids;
            } catch {
              if (msg?.data === HEARTBEAT) {
                return;
              }
            }
          }
        },
        async onopen(response) {
          if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
            return; // everything's good
          } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            setIsError(true);
            abortConnection();
            Sentry.captureException(new Error("Ask ai failed"), {
              extra: { response },
            });
          } else {
            // throw new RetriableError();
          }
        },
        onclose: async () => {
          setIsLoading(false);
          try {
            if (projectId) {
              const response = await dispatch(getAtlasRequirements({ projectId }));
              const decodedResponse = unwrapResult(response);
              const filteredRequirements = decodedResponse.filter(({ id }) => requirementIds.current?.includes(id));

              dispatch(setFilteredRequirements(filteredRequirements));
            }
          } catch {}
        },
        onerror(err) {
          setIsError(true);
          abortConnection();

          if (err instanceof Error) {
            Sentry.captureException(err);
            throw err;
          }
        },
      });
    },
    [
      abortConnection,
      dispatch,
      extractionId,
      isLoading,
      localValue,
      projectId,
      query,
      resetForm,
      setToast,
      useAuth0Header,
      workspace_id,
    ]
  );

  useEffect(
    () => () => {
      dispatch(setFilteredRequirements([]));
    },
    [dispatch]
  );

  return {
    handleAskAi,
    resetForm,
    setQuery,
    query,
    isLoading: isLoading || delayedAnswer.length !== answer.length,
    answer: delayedAnswer,
    abortConnection,
    isError,
  };
};
