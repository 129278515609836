import { QUICK_ACTIONS } from "./constants";
import { setAssistantPrompt, setHideBlock } from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch } from "store/storeTypes";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { useAssistant } from "./hooks";
import { useEffect, useState } from "react";

const WritingAssistantInputQuickActions = () => {
  const [quickActionSelected, setQuickActionSelected] = useState(false);
  const dispatch = useAppDispatch();
  const { submitMessage } = useAssistant();

  const handleQuickAction = (value: string) => {
    dispatch(setAssistantPrompt(value));
    dispatch(setHideBlock(true));
    setQuickActionSelected(true);
  };

  useEffect(() => {
    if (quickActionSelected) {
      submitMessage();
      setQuickActionSelected(false);
    }
  }, [quickActionSelected, submitMessage]);

  return (
    <div className="flex w-full justify-start items-center gap-3 px-4 pb-4 pt-4">
      {QUICK_ACTIONS.map(({ label, value, icon: IconComponent }) => {
        return (
          <Tooltip key={label} content={label}>
            <button className="text-slate-500 hover:text-gray-900" key={label} onClick={() => handleQuickAction(value)}>
              <IconComponent size={15} />
            </button>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default WritingAssistantInputQuickActions;
