import { useCallback, useEffect } from "react";
import {
  setAssistantPrompt,
  setHideBlock,
  setHighlightedText,
  setStreamingState,
} from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import { useNotification } from "context/notificationContext";
import { useLocalStorage } from "hook/useLocalStorage";
import {
  AssistantBlockType,
  BlockSource,
  Storage as ImmutableStorage,
} from "components/copilot/CopilotSchemaImmutableTypes";
import { Storage, VultronBlock, WritingAssistant } from "components/copilot/CopilotSchemaTypes";
import { DELIMITER } from "./constants";
import { createWritingAssistantBlock } from "utils/complianceMatrix";
import { getWordCount } from "utils/getWordCount";
import { useTrackUserMetric } from "utils/metrics";
import { useObserveSseController } from "hook/useObserveSseController";
import { useMutation, useStorage } from "YJSProvider/createYJSContext";
import { find, LiveList, LiveObject, update } from "YJSProvider/LiveObjects";
import { StreamEvent, StreamEventType, StreamStopMsgData } from "types/Streaming/streamConfig";
import { HEARTBEAT } from "const-values/Stream";
import * as Sentry from "@sentry/react";
import { apiUrl } from "config/vultronConfig";

type PastMessage = {
  content: string;
  is_assistant: boolean;
  sent_at: string;
  sources: BlockSource[];
};

type SendMessageVariables = {
  project_id: string;
  win_themes: string[];
  user_request: string;
  search_file_ids: string[];
  past_messages: PastMessage[];
  use_internet?: boolean;
  hideBlock?: boolean;
};

let controller = new AbortController();
export const useAssistant = () => {
  const { localValue } = useLocalStorage("vultron_user_token", "");
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0", "");
  const useAuth0Header = use_auth0 === true;
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((store) => store.auth);
  const { activeProject } = useAppSelector((root) => root.project);
  const { prompt, selectedFiles, streamState, enableInternet, hideBlock, highlightedText } = useAppSelector(
    (root) => root.writingAssistant
  );
  const myConversation = useStorage(
    (root) =>
      (root.writing_assistant as ImmutableStorage["writing_assistant"])?.[currentUser?.id || ""]?.conversation || []
  );
  const canSubmit = !!prompt.trim() && !streamState.isStreamingInProgress && !!activeProject?.internal_contract.id;
  const canRefresh = !streamState.isStreamingInProgress && !!activeProject?.internal_contract.id;
  const trackUserEvent = useTrackUserMetric();

  const sendMessage = useMutation(
    ({ storage }, messagePayload: SendMessageVariables, refreshId?: string) => {
      let text = "";
      let vultronBlock: LiveObject<VultronBlock>;
      const isNewMessage = !refreshId;
      const myConversation = (storage.get("writing_assistant") as Storage["writing_assistant"])
        ?.get(currentUser?.id || "")
        ?.get("conversation") as WritingAssistant["conversation"] | undefined;

      const determineBlockType = () => {
        if (vultronBlock.get("enableInternet")) {
          return "internet";
        } else if (vultronBlock.get("promptSources")?.length) {
          return "content search";
        } else {
          return "other";
        }
      };

      const sourceLength = () => {
        if (vultronBlock.get("sources")?.length) {
          return vultronBlock.get("sources")?.length;
        } else {
          return 0;
        }
      };

      if (!isNewMessage && myConversation) {
        const foundBlock = find(
          myConversation,
          (block) => block.get("type") === AssistantBlockType.VultronBlock && block.get("id") === refreshId
        ) as LiveObject<VultronBlock>;
        if (!foundBlock) return;
        vultronBlock = foundBlock;
        dispatch(setStreamingState({ isStreamingInProgress: true, blockId: refreshId }));
        update(vultronBlock, { error: false, sources: new LiveList([] as LiveObject<BlockSource>[]) });
      } else {
        const userBlock = createWritingAssistantBlock(AssistantBlockType.CoreBlock, {
          body: messagePayload.user_request,
          hideBlock,
        });
        vultronBlock = createWritingAssistantBlock(AssistantBlockType.VultronBlock, {
          prompt: messagePayload.user_request,
          promptSources: messagePayload.search_file_ids,
          error: false,
          enableInternet: messagePayload.use_internet,
        }) as LiveObject<VultronBlock>;
        myConversation?.push([userBlock]);
        myConversation?.push([vultronBlock]);
        dispatch(setStreamingState({ isStreamingInProgress: true, blockId: vultronBlock.get("id") }));
        dispatch(setAssistantPrompt(""));
        dispatch(setHideBlock(false));
      }

      fetchEventSource(`${apiUrl}/chat/send_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Workspace: `Workspace ${workspace_id}`,
          Authorization: `Bearer ${localValue}`,
          "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
          Accept: "application/json",
        },
        body: JSON.stringify(messagePayload),
        signal: controller.signal,
        openWhenHidden: true,
        onmessage(msg) {
          if (msg.event === "FatalError") {
          }

          if (msg.event === StreamEvent.StreamRestart) {
            text = msg.data || "";
            dispatch(setStreamingState({ streamCopy: text }));
            update(vultronBlock, { body: text || "", sources: new LiveList([]), error: false });
            return;
          }

          if (msg.event === StreamEvent.StreamStop) {
            abortConnection();

            try {
              const parsedData = JSON.parse(msg.data) as StreamStopMsgData;
              if (parsedData.type === StreamEventType.Repetition) {
                text = "";
                update(vultronBlock, { body: "", sources: new LiveList([]), error: true });
              }
              if (parsedData.reason) {
                setToast.error({
                  msg: parsedData.reason,
                });
              }
            } catch {}
            return;
          }
          if (msg?.data === HEARTBEAT) return;

          if (!!msg.data?.length) {
            try {
              const parsed = JSON.parse(msg.data);
              if (typeof parsed !== "object" && !parsed?.sources) throw new Error("error");
              const sources = (parsed.sources as BlockSource[]).map((source) => {
                const patchedSource = { ...source, date: source.date || new Date().toISOString() };
                return new LiveObject(patchedSource);
              });
              vultronBlock.set("sources", new LiveList(sources));
            } catch {
              if (msg.data !== DELIMITER) {
                text += msg.data;
                dispatch(setStreamingState({ streamCopy: text }));
              }
            }
          } else if (typeof msg.data === "string") {
            text += "\n";
            dispatch(setStreamingState({ streamCopy: text }));
            vultronBlock.set("body", text);
          }
        },
        async onopen(response) {
          if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
            return; // everything's good
          } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            setToast.error({
              title: "Unable to send message",
              msg: "We were unable to send message due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
            });
            vultronBlock.set("error", true);
            dispatch(setStreamingState({}));
            Sentry.captureException(new Error("Writing assistant failed"), {
              extra: { response },
            });
          } else {
          }
        },
        onclose() {
          vultronBlock.set("body", text);
          setTimeout(() => dispatch(setStreamingState({})), 100);
          trackUserEvent("Chat: Message Recieved", {
            type: determineBlockType(),
            word_count: getWordCount(vultronBlock.get("body") || ""),
            number_sources: sourceLength(),
          });
        },
        onerror(err) {
          setToast.error({
            title: "Unable to send message",
            msg: "We were unable to send message due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
          });
          vultronBlock.set("error", true);
          dispatch(setStreamingState({}));
          if (err instanceof Error) {
            Sentry.captureException(err);
            throw err; // rethrow to stop the operation
          } else {
          }
        },
      });
    },
    [dispatch]
  );

  const abortConnection = useCallback(() => {
    controller.abort();
    controller = new AbortController();
    dispatch(setStreamingState({}));
  }, [dispatch]);

  useObserveSseController(abortConnection);

  const submitMessage = useMutation(
    ({ storage }) => {
      if (!canSubmit) return;
      dispatch(setHighlightedText(""));
      const userRequest = highlightedText ? `${prompt}: ${highlightedText}` : prompt;

      const winThemes =
        (storage.get("win_themes") as Storage["win_themes"])?.toJSON()?.filter(({ content }) => !!content) || [];

      const lastTwenty = myConversation.slice(-20).filter(({ body }) => !!body.trim());

      sendMessage({
        win_themes: winThemes?.map(({ content }) => content),
        project_id: activeProject.internal_contract.id,
        user_request: userRequest,
        search_file_ids: selectedFiles.map(({ id }) => id),
        past_messages: lastTwenty.map(({ body, type, updated_at, sources }) => ({
          content: body,
          is_assistant: type === AssistantBlockType.VultronBlock,
          sent_at: updated_at,
          sources: (sources || [])
            .filter((source) => !!source.content?.trim())
            .map((source) => ({
              ...source,
              date: source.date || updated_at,
            })),
        })),
        use_internet: enableInternet,
        hideBlock,
      });
    },
    [activeProject?.internal_contract.id, canSubmit, myConversation, prompt, selectedFiles, sendMessage]
  );

  const refreshMessage = useMutation(
    ({ storage }, refreshId: string) => {
      if (!canRefresh) return;

      const winThemes =
        (storage.get("win_themes") as Storage["win_themes"])?.toJSON()?.filter(({ content }) => !!content) || [];
      const blockIdx = myConversation?.findIndex(({ id }) => refreshId === id);
      const block = myConversation[blockIdx];
      if (blockIdx === -1) return;
      if (block?.type !== AssistantBlockType.VultronBlock || !block.prompt?.trim()) return;
      const lastTwenty = myConversation
        .slice(0, blockIdx)
        .slice(-20)
        .filter(({ body }) => !!body.trim());

      sendMessage(
        {
          win_themes: winThemes?.map(({ content }) => content),
          project_id: activeProject.internal_contract.id,
          user_request: block.prompt,
          search_file_ids: block.promptSources || [],
          past_messages: lastTwenty.map(({ body, type, updated_at, sources }) => ({
            content: body,
            is_assistant: type === AssistantBlockType.VultronBlock,
            sent_at: updated_at,
            sources: (sources || [])
              .filter((source) => !!source.content?.trim())
              .map((source) => ({
                ...source,
                date: source.date || updated_at,
              })),
          })),
          use_internet: block.enableInternet,
        },
        refreshId
      );
    },
    [activeProject?.internal_contract.id, canRefresh, myConversation, sendMessage]
  );

  return { abortConnection, refreshMessage, submitMessage };
};

export const useResetWritingAssistantSelection = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setHighlightedText(""));
    return () => {
      dispatch(setHighlightedText(""));
    };
  }, [dispatch]);
};
