/** @jsxImportSource @emotion/react */

import { Modal } from "../../organisms/modal";
import { ComponentProps } from "react";
import { useAppDispatch } from "store/storeTypes";
import { removeDocIdFromSuccessfulReviews } from "store/reducers/proposal/proposalReducer";
import { ReactComponent as CircleCheck } from "../../../Assets/circle-check.svg";

export interface ReviewEngineModalProps extends Pick<ComponentProps<typeof Modal>, "open"> {
  referenceId: string;
}

const ReviewEngineModal = ({ referenceId, ...props }: ReviewEngineModalProps) => {
  const dispatch = useAppDispatch();

  return (
    <Modal
      title="Review complete dialog"
      header={
        <div className="flex gap-1 items-center">
          <CircleCheck className="h-6 w-6 text-green-700" /> Review Complete
        </div>
      }
      onOpenChange={() => dispatch(removeDocIdFromSuccessfulReviews(referenceId))}
      body={
        <div className="flex flex-col gap-2.5 px-6 text-sm">
          No additional feedback was identified for this proposal.
        </div>
      }
      footer={
        <>
          <button
            className="text-white text-sm bg-gray-900 px-3 py-2 rounded"
            onClick={() => dispatch(removeDocIdFromSuccessfulReviews(referenceId))}
          >
            Acknowledge
          </button>
        </>
      }
      {...props}
    />
  );
};

export default ReviewEngineModal;
