export const YJS_OPERATIONS = {
  EXTRACTION: {
    SELECT_TEMPLATE: "Extraction::Select template",
    SELECT_CONTEXT_TEMPLATE: "Extraction::Select context template",
    SELECT_LIBRARY_TEMPLATE: "Extraction::Select library template",
    DESELECT_TEMPLATE: "Extraction::Deselect template",
    DESELECT_CONTEXT_TEMPLATE: "Extraction::Deselect context template",
    SET_STATUS: "Extraction::Set status",
    VALIDATE_IN_PROGRESS: "Extraction::Set status to In Progress",
    SET_STEP: "Extraction::Set step",
    SET_NAME: "Extraction::Set name",
    DELETE_EXTRACTION: "Extraction::Delete",
    SET_SUBSECTION_TITLE: "Extraction::Set subsection title",
    SET_SECTION_TITLE: "Extraction::Set section title",
    SET_VOLUME_TITLE: "Extraction::Set volume title",
    SET_REQUIREMENT_BULK_SKIPPED: "Extraction::Set requirement bulk skipped",
    SET_REQUIREMENT_BULK_SELECTED: "Extraction::Set requirement bulk selected",
    SET_REQUIREMENT_BULK_UNASSIGN: "Extraction::Set requirement bulk unassign",
    SET_REQUIREMENT_BULK_ASSIGN: "Extraction::Set requirement bulk assign",
    SET_REQUIREMENT_SKIPPED: "Extraction::Set requirement skipped",
    SET_REQUIREMENT_SELECTED: "Extraction::Set requirement selected",
    SET_REQUIREMENT_UNASSIGN: "Extraction::Set requirement unassign",
    SET_INSTANT_DRAFT_STRICTNESS: "Extraction::Set instant draft strictness",
    SET_INSTANT_DRAFT_CONFIRMATION: "Extraction::Set instant draft confirmation",
    SET_INSTANT_DRAFT_VOLUMES: "Extraction::Set instant draft volumes",
    SET_INSTANT_DRAFT_SECTIONS: "Extraction::Set instant draft sections",
    SET_INSTANT_DRAFT_VOLUMES_SECTIONS_SELECTION: "Extraction::Instant draft volumes and sections selection",
    SET_INSTANT_DRAFT_PAGE_LIMIT: "Extraction::Set instant draft page limit",
    SET_INSTANT_DRAFT_STATUS: "Extraction::Set instant draft status",
    SYNC_INSTANT_DRAFT_VOLUMES: "Extraction::Sync instant draft volumes",
    TRIGGER_INSTANT_DRAFT: "Extraction::Trigger instant draft",
    MERGE_REQUIREMENTS: "Extraction::Merge requirements",
    UNMERGE_REQUIREMENTS: "Extraction::Unmerge requirements",
    ADD_SECTION: "Extraction::Add section",
    ADD_SUBSECTION: "Extraction::Add subsection",
    ADD_VOLUME: "Extraction::Add volume",
    REORDER_VOLUME: "Extraction::Reorder volume",
    REORDER_SECTION: "Extraction::Reorder section",
    REORDER_SUBSECTION: "Extraction::Reorder subsection",
    DELETE_VOLUME: "Extraction::Delete volume",
    DELETE_SECTION: "Extraction::Delete section",
    DELETE_SUBSECTION: "Extraction::Delete subsection",
    MOVE_SECTION: "Extraction::Move section",
    CREATE_EXTRACTION: "Extraction::Create",
  },
};

export const MAX_OPERATION_LIST_LENGTH = 499;
