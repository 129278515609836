import { Button } from "components/editor/components";
import { Modal } from "components/organisms/modal";
import { ComponentProps } from "react";

interface Props extends Pick<ComponentProps<typeof Modal>, "open" | "onOpenChange"> {}

const HelpModal = (props: Props) => {
  return (
    <Modal
      title="How to use"
      header="How to use"
      contentProps={{ css: { maxWidth: "575px" } }}
      {...props}
      body={
        <div className="flex flex-col gap-2.5 min-w-[150px] m-1 min-h-0">
          <div className="flex flex-col gap-2 overflow-y-auto px-5">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-3 flex-row">
                <div className="bg-white">
                  <div className="border-2 bg-[rgba(14,120,8,.2)] border-[rgb(14,120,8)] w-4 h-4" />
                </div>
                <span className="text-base font-semibold">Proposal Outline & Compliance Matrix</span>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-sm text-gray-500 pb-2">
                  The text block is tracked as a requirement in both the proposal outline and the compliance matrix.
                </div>
                <div className="text-sm text-gray-500 pb-2">
                  Selected results will be added to the <strong>requirements and drafts tab</strong> once complete.
                </div>
                <div>
                  <span className="font-semibold text-sm">When to use</span>{" "}
                  <div className="text-gray-500 text-sm">
                    Ideal for requirements that demand a direct response within the proposal.
                  </div>
                </div>
                <div>
                  <span className="font-semibold text-sm">How to use</span>{" "}
                  <div className="flex flex-col gap-1 text-gray-500 text-sm">
                    <div className="flex flex-row gap-2">
                      <div className="w-[13px]">1.</div>Drag to highlight the relevant text block.
                    </div>
                    <div className="flex flex-row gap-2">
                      <div className="w-[13px]">2.</div>Navigate to the corresponding section in the right panel
                      outline.
                    </div>
                    <div className="flex flex-row gap-2">
                      <div className="w-[13px]">3.</div>Hover over the target location to insert it into the table.
                    </div>
                    <div className="flex flex-row gap-2">
                      <div className="w-[13px]">4.</div>Click to confirm insertion.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-3 flex-row">
                <div className="bg-white">
                  <div className="border-2 border-action bg-action/20 w-4 h-4" />
                </div>
                <span className="text-base font-semibold">Compliance Matrix Only</span>
              </div>
              <div className="flex flex-col gap-2 mb-2">
                <div className="text-sm text-gray-500 pb-2">
                  The text block is tracked solely as a requirement in the compliance matrix and will be imported
                  directly into the requirements tab for management and export.
                </div>
                <div className="text-sm text-gray-500 pb-2">
                  Selected results will be added to the <strong>requirements tab</strong> only once complete.
                </div>
                <div>
                  <span className="font-semibold text-sm">When to use</span>{" "}
                  <div className="text-gray-500 text-sm">
                    Ideal for requirements that need tracking but do not require a direct response within the proposal.
                  </div>
                </div>
                <div>
                  <span className="font-semibold text-sm">How to use</span>{" "}
                  <div className="text-gray-500 text-sm">
                    Click the text block to include or exclude the requirement from the compliance matrix.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-5 w-full">
            <Button onClick={() => props.onOpenChange?.(false)} variant="primaryBlack" size="md" className="w-full">
              Continue
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default HelpModal;
