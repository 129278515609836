/** @jsxImportSource @emotion/react */

import { useEffect, useRef } from "react";
import SideBar from "components/dashboard/SideBar";
import Loader from "utils/Spinner";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocalStorage } from "hook/useLocalStorage";
import { getAllDrpOptions } from "store/reducers/utilsRouter";
import { getContractManagerItems, getNotificationFeedsApi } from "api/api";
import { setFeed } from "store/reducers/feedReducerSlice";
import { setContractManager } from "store/reducers/contractManagerReducerSlice";
import { SIDEBAR } from "const-values/Layout";
import { useFlags } from "hook/useFlags";
import { TOP_BANNER } from "const-values/Banner";
import TopBanner from "components/atoms/top-banner";
import { fetchFileStorage } from "store/reducers/driveReducerSlice";
import GlobalModals from "components/organisms/global-modals";

// -------------- Dashboard Wrapper ---------------
const Dashboard = () => {
  const dispatch = useDispatch();
  const { isLoadingWorkSpaces, myWorkSpaces } = useSelector((store) => store.auth);
  const { localValue } = useLocalStorage("vultron_workspace_id", "");
  let { pathname } = useLocation();
  const contentRef = useRef(null);
  const flags = useFlags();

  // scroll top when tab changes
  useEffect(() => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(fetchFileStorage());
  }, [dispatch]);

  // fetch dropdown list
  useEffect(() => {
    if (!myWorkSpaces || !localValue) return;
    dispatch(getAllDrpOptions());
    getNotificationFeedsApi()
      .then((res) => {
        res?.data?.forEach((item) => {
          item.viewedBy = item?.contract?.viewed_by;
        });
        dispatch(
          setFeed({
            data: [...res.data],
            loading: false,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setFeed({
            data: [],
            loading: false,
          })
        );
      });

    // fetch contract data after every five seconds in background
    let tm = setInterval(() => {
      getContractManagerItems()
        .then((res) => {
          let list = res.data;
          if (list.length > 0) {
            dispatch(setContractManager({ data: list, loading: false }));
          }
        })
        .catch(() => {
          dispatch(setContractManager({ loading: false }));
        });
    }, 3000);

    return () => {
      clearInterval(tm);
    };
  }, []);

  if (isLoadingWorkSpaces)
    return (
      <div className="h-screen mx-auto flex content-center items-center justify-center py-5 px-2 text-center sm:px-4">
        <Loader />
      </div>
    );

  if (pathname?.toString()?.includes("create-workspace")) {
    return <Outlet />;
  }
  if (pathname?.toString()?.includes("select-workspace")) {
    return <Outlet />;
  }
  if (!myWorkSpaces || !localValue) return <Navigate to={"/select-workspace"} replace />;

  return (
    <>
      <GlobalModals />
      {!!flags.topBannerV2?.bannerCopy && <TopBanner copy={flags.topBannerV2?.bannerCopy} />}
      <div
        className="flex w-full scrollbar-none h-screen overflow-hidden"
        css={[!!flags.topBannerV2?.bannerCopy && { height: `calc(100vh - ${TOP_BANNER.HEIGHT}px)` }]}
      >
        {/* Sidebar */}
        <div className="w-full" css={{ maxWidth: SIDEBAR.MAX_WIDTH }}>
          <SideBar />
        </div>
        {/* Dashboard content screen wrapper */}
        <div
          className="flex-grow flex-1 bg-[#f8f9fa] flex flex-col"
          css={{ width: `calc(100% - ${SIDEBAR.MAX_WIDTH}PX)` }}
          ref={contentRef}
        >
          {" "}
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
