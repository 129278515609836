import * as Y from "yjs";
import * as awarenessProtocol from "y-protocols/awareness";
import { createContext, RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { authenticateToProposal, getCopilotOrProposalStorage as getCopilotOrProposalYDoc } from "./client";
import { OthersMap, YJSProvider } from "./YJSProvider";
import { CopilotPresencePage } from "types/Presence";
import { useProxyRef } from "hook/useProxyRef";
import { isArray, isEqual, throttle } from "lodash";
import { ToImmutable } from "./LiveObjects";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { useFlags } from "hook/useFlags";

type Status = "connecting" | "connected" | "reconnecting" | "disconnected";

interface BaseMeta {
  presence: {};
  info: {
    id: string;
  };
}
export interface Room<P extends BaseMeta, S, A extends (...args: any) => any> {
  id: string;
  statusRef: RefObject<Status>;
  storageRef: RefObject<ToImmutable<S> | undefined>;
  awareness: awarenessProtocol.Awareness;
  awarenessDoc: Y.Doc;
  awarenessProvider?: YJSProvider;
  getStorageMap: () => Y.Map<S>;
  updatePresence: (presence: P["presence"]) => void;
  authenticate: (override: boolean) => Promise<string>;
  authParams?: Parameters<A>;
  on: (event: "storage", listener: (storage: ToImmutable<S>) => void) => void;
  off: (event: "storage", listener: (storage: ToImmutable<S>) => void) => void;
}

export const createYJSContext = <P extends BaseMeta, S, A extends (...args: any) => any>(
  auth: A,
  getYDocUpdates: (...params: Parameters<A>) => Promise<Uint8Array[]>
) => {
  const RoomContext = createContext<Room<P, S, A>>(null!);

  interface RoomProviderProps {
    id: string; // the doc id for the Y.Doc
    authParams?: Parameters<A>;
    children: React.ReactNode;
    initialPresence?: object;
    fallback: React.ReactNode;
  }

  const RoomProvider = ({ id, children, initialPresence = {}, authParams, fallback }: RoomProviderProps) => {
    const defaultPresence: object = {
      cursor: null,
    };
    const flags = useFlags();
    const emergencyYjs = flags.emergencyYjs;
    const [loaded, setLoaded] = useState(false);
    const [awarenessDoc] = useState(() => new Y.Doc());
    const awareness = useMemo(() => new awarenessProtocol.Awareness(awarenessDoc), [awarenessDoc]);
    const [token, setToken] = useState<string | undefined>();
    const getStorageMap = useCallback(() => awarenessDoc.getMap<S>("storage"), [awarenessDoc]);
    const statusRef = useRef<Status>("connecting");
    const storageRef = useRef<ToImmutable<S> | undefined>(getStorageMap().toJSON() as ToImmutable<S>);
    const storageListeners = useRef(new Set<(storage: ToImmutable<S>) => void>());
    const [awarenessProvider, setAwarenessProvider] = useState<YJSProvider>();

    const updatePresence = useCallback(
      (presence: Partial<object>) => {
        const prevState = awareness.getLocalState()?.["presence"] || {};
        awareness.setLocalStateField("presence", { ...prevState, ...presence });
      },
      [awareness]
    );

    const authenticate = useCallback(
      async (override?: boolean) => {
        if (token && !override) return token;
        const params = isArray(authParams) ? authParams : [];
        const authToken = await auth(...params);
        setToken(authToken);
        return authToken;
      },
      [token]
    );

    const room: Room<P, S, A> = useMemo(
      () => ({
        id,
        statusRef,
        storageRef,
        awareness,
        awarenessDoc,
        awarenessProvider,
        getStorageMap,
        updatePresence,
        authenticate,
        authParams,
        on: (event, listener) => {
          if (event === "storage") {
            storageListeners.current.add(listener);
          }
        },
        off: (event, listener) => {
          if (event === "storage") {
            storageListeners.current.delete(listener);
          }
        },
        emergencyYjs,
      }),
      [id, statusRef, awareness, awarenessDoc, updatePresence, getStorageMap, authenticate, emergencyYjs]
    );

    useEffect(() => {
      const initYJS = async () => {
        if (emergencyYjs) {
          // Offline mode
          try {
            const params = isArray(authParams) ? authParams : [];
            const updates = await getYDocUpdates(...(params as Parameters<A>));
            for (const bin of updates) {
              Y.applyUpdate(awarenessDoc, bin);
            }
            statusRef.current = "connected";
            setLoaded(true);
          } catch (error) {
            console.error("Error initializing offline mode:", error);
            statusRef.current = "disconnected";
          }
        } else if (!emergencyYjs) {
          // Normal connection mode
          statusRef.current = "connecting";
          const awarenessProvider = new YJSProvider(room, awarenessDoc, { awareness, disableAutoConnect: true });
          setAwarenessProvider(awarenessProvider);

          // Listen to the synced event to know when the provider is connected
          awarenessProvider.on("synced", (synced: boolean) => {
            if (synced) setLoaded(true);
            const yDocBinary = Y.encodeStateAsUpdate(awarenessDoc);

            const firstTenBytes = yDocBinary.slice(0, 10);
            Array.from(firstTenBytes)
              .map((byte) => byte.toString(16).padStart(2, "0"))
              .join("");
            // Assuming yDocBinary is the Uint8Array you received from the server
            const lastTenBytes = yDocBinary.slice(-10);
            Array.from(lastTenBytes)
              .map((byte) => byte.toString(16).padStart(2, "0"))
              .join("");
            updatePresence({ ...defaultPresence, ...initialPresence });
          });
          awarenessProvider.on("status", ({ status }: { status: "disconnected" | "connecting" | "connected" }) => {
            statusRef.current = status;
            awarenessDoc.emit("providerStatus", [status]);
          });

          // Authenticate after the provider is created, and we are listening for sync event
          awarenessProvider.authenticate();

          return () => {
            awarenessProvider.destroy();
            awarenessDoc.destroy();
          };
        }
      };

      initYJS();
    }, []);

    useEffect(() => {
      const map = getStorageMap();
      const listener = throttle(() => {
        const storage = map.toJSON() as ToImmutable<S>;
        storageRef.current = storage;
        storageListeners.current.forEach((listener) => listener(storage));
      }, 0);
      map.observeDeep(listener);
      return () => map.unobserveDeep(listener);
    }, [getStorageMap]);

    if (!loaded) return <>{fallback}</>;
    return <RoomContext.Provider value={room}>{children}</RoomContext.Provider>;
  };

  const useRoom = () => {
    return useContext(RoomContext);
  };

  const useUpdateMyPresence = () => {
    const room = useRoom();
    return room.updatePresence;
  };

  const useSelf = () => {
    const room = useRoom();
    const [self, setSelf] = useState(room.awareness.getLocalState());

    useEffect(() => {
      const listener = (state: Object) => {
        setSelf(state);
      };
      room.awareness.on("self", listener);
      return () => {
        room.awareness.off("self", listener);
      };
    }, [room.awareness]);

    return self;
  };

  const getUserMeta = ([id, state]: [id: number, state: any]): P => {
    return {
      presence: {},
      ...state,
      connectionId: id,
    };
  };

  const getOthers = (state: OthersMap, clientId: number) => {
    const copiedMap = new Map(state);
    // Remove self from map
    copiedMap.delete(clientId);
    return Array.from(copiedMap.entries()).map(getUserMeta);
  };
  const useOthers = (compare?: (a: any, b: any) => boolean) => {
    const room = useRoom();
    const [others, setOthers] = useState<P[]>(getOthers(room.awareness.getStates(), room.awareness.clientID));
    const othersProxy = useProxyRef(others);

    useEffect(() => {
      const listener = (state: OthersMap) => {
        const nextState = getOthers(state, room.awareness.clientID);
        const stateChanged = compare ? !compare(nextState, othersProxy.current) : true;
        if (stateChanged) setOthers(nextState);
      };
      room.awareness.on("others", listener);
      return () => {
        room.awareness.off("others", listener);
      };
    }, [room.awareness]);

    return others;
  };

  const useOthersMapped = <T,>(iterator: (obj: P) => T, compare?: (a: any, b: any) => boolean) => {
    const room = useRoom();
    const transform = (map: OthersMap): [number, T][] => {
      const copiedMap = new Map(map);
      // Remove self from map
      copiedMap.delete(room.awareness.clientID);
      return Array.from(copiedMap.entries()).map(([id, value]) => {
        return [id, iterator(getUserMeta([id, value]))];
      });
    };
    const [others, setOthers] = useState<[number, T][]>(transform(room.awareness.getStates()));
    const othersProxy = useProxyRef(others);

    useEffect(() => {
      const listener = (state: OthersMap) => {
        const nextState = transform(state);
        const stateChanged = compare ? !compare(nextState, othersProxy.current) : true;
        if (stateChanged) setOthers(nextState);
      };
      room.awareness.on("others", listener);
      return () => {
        room.awareness.off("others", listener);
      };
    }, [room.awareness]);

    return others;
  };

  const getStorage = (room: Room<P, S, A>, storageFunc: (obj: any) => any) => {
    const storage = room.storageRef.current || room.getStorageMap().toJSON();
    return storageFunc(storage);
  };
  const useStorage = <T,>(
    storageFunc: (obj: ToImmutable<S>) => T,
    compare: (a: any, b: any) => boolean = isEqual
  ): T => {
    const room = useRoom();
    const storageFuncRef = useProxyRef(storageFunc);
    const [storage, setStorage] = useState(getStorage(room, storageFunc));
    const storageProxy = useProxyRef(storage);

    const updateState = useCallback(() => {
      const nextState = getStorage(room, storageFuncRef.current);
      const stateChanged = !compare(nextState, storageProxy.current);
      if (stateChanged) setStorage(nextState);
    }, [room]);

    useEffect(() => {
      const listener = (_storage: ToImmutable<S>) => {
        updateState();
      };
      listener(getStorage(room, storageFuncRef.current));
      room.on("storage", listener);
      return () => {
        room.off("storage", listener);
      };
    }, [storageFuncRef, room]);

    useEffect(() => {
      updateState();
    }, [storageFunc, updateState]);

    return storage;
  };

  const useMutation = <T extends any[], A>(
    mutation: (context: { storage: Y.Map<any> }, ...args: T) => A,
    deps: React.DependencyList
  ) => {
    const room = useRoom();
    const storage = room.getStorageMap();
    return useCallback(
      (...args: T) => storage.doc!.transact(() => mutation({ storage }, ...args)),
      [storage, mutation, ...deps]
    );
  };

  const useStatus = () => {
    const room = useRoom();
    const [status, setStatus] = useState(room.statusRef.current);

    useEffect(() => {
      const listener = (status: Status) => {
        setStatus(status);
      };
      room.awarenessDoc.on("providerStatus", listener);
      return () => {
        room.awarenessDoc.off("providerStatus", listener);
      };
    }, []);

    return status;
  };

  const useUpdates = <T,>(updateKey: string, def: T) => {
    const storage = useStorage((storage) => {
      // @ts-ignore
      const updates = storage.updates || {};
      return updates[updateKey] || def;
    });

    return storage as T;
  };

  return {
    RoomProvider,
    useRoom,
    useUpdateMyPresence,
    useSelf,
    useOthers,
    useOthersMapped,
    useStorage,
    useMutation,
    useStatus,
    useUpdates,
  };
};

interface UserMeta {
  connectionId: number;
  presence: {
    name?: string;
    activePage?: CopilotPresencePage;
    cursor?: {
      percentX: number;
      percentY: number;
    } | null;
    proposalRoomId?: string;
    selectedId?: string | null;
  };
  info: {
    name: string;
    imageUrl: string;
    id: string;
  };
}

export const {
  RoomProvider,
  useRoom,
  useUpdateMyPresence,
  useSelf,
  useOthers,
  useOthersMapped,
  useStorage,
  useMutation,
  useStatus,
  useUpdates,
} = createYJSContext<UserMeta, Storage, typeof authenticateToProposal>(
  authenticateToProposal,
  getCopilotOrProposalYDoc
);
