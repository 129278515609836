import { Storage as ImmutableStorage } from "../CopilotSchemaImmutableTypes";
import { renderBlock } from "./utils";
import { useAppSelector } from "store/storeTypes";
import { Empty } from "components/molecules/empty";
import { useInitWritingAssistant } from "./hooks";
import TypingEffect from "components/TypingEffect";
import tw from "twin.macro";
import { Fragment, useEffect, useRef, useState } from "react";
import { ArrowDown } from "lucide-react";
import { useStorage } from "YJSProvider/createYJSContext";

const WritingAssistantConversation = () => {
  const { streamState } = useAppSelector((store) => store.writingAssistant);
  const { isStreamingInProgress, streamCopy, blockId } = streamState;
  const { currentUser } = useAppSelector((store) => store.auth);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [autoScroll, setAutoScroll] = useState<boolean>(true);
  const myConversation = useStorage(
    (root) => (root.writing_assistant as ImmutableStorage["writing_assistant"])?.[currentUser?.id || ""]?.conversation
  );

  useInitWritingAssistant(currentUser?.id, myConversation);
  const isEmpty = !myConversation?.length;

  useEffect(() => {
    if (autoScroll) {
      scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight + 21);
      setAutoScroll(true);
    }
  }, [autoScroll, blockId, isStreamingInProgress, streamCopy]);

  return (
    <div className="relative flex w-full items-center justify-center flex-1 overflow-hidden">
      {isEmpty && !isStreamingInProgress ? (
        <Empty heading="How can I assist you today?" title="Send a message to get started" />
      ) : (
        <>
          {!autoScroll && (
            <button
              onClick={() => {
                if (blockId && myConversation?.[myConversation?.length - 1]?.id !== blockId) {
                  document
                    .getElementById(`assistant-block-${blockId}`)
                    ?.scrollIntoView({ block: "start", behavior: "smooth" });
                } else {
                  scrollRef.current?.scrollTo({
                    top: scrollRef.current?.scrollHeight + 5,
                    behavior: "smooth",
                  });
                }
              }}
              className="absolute flex justify-center items-center w-8 h-8 z-[1] rounded-full right-4 bottom-2 shadow-sharp-thin text-action text-[20px] bg-action-light backdrop-blur-[2px]"
            >
              {isStreamingInProgress ? (
                <TypingEffect
                  css={{
                    paddingLeft: 3,
                    paddingRight: 0,
                    paddingBottom: 6,
                    "& > div": tw`bg-action h-1 w-1 mr-[3px]`,
                  }}
                />
              ) : (
                <ArrowDown size={14} />
              )}
            </button>
          )}
          <div
            onScroll={(e) => {
              const shouldAutoScroll =
                e.currentTarget.scrollTop + 5 >= e.currentTarget.scrollHeight - e.currentTarget.clientHeight;
              if (shouldAutoScroll !== autoScroll) setAutoScroll(shouldAutoScroll);
            }}
            ref={scrollRef}
            className="flex flex-col w-full items-start h-full gap-8 px-4 pt-5 pb-12 overflow-y-auto"
          >
            {myConversation?.map((block) => {
              const blockElement = renderBlock(block);
              return <Fragment key={block.id}>{blockElement}</Fragment>;
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default WritingAssistantConversation;
