export const colorList = [
  { color: "#4F00B2", bg: "#D9BBFF" },
  { color: "#339E00", bg: "#B2DC9E" },
  { color: "#00809D", bg: "#90C8D4" },
  { color: "#E8AB7E", bg: "#CF5800" },
  { color: "#AA0000", bg: "#FFBBBB" },
  { color: "#339E00", bg: "#B2DC9E" },
  { color: "#AA0000", bg: "#FFBBBB" },
  { color: "#E8AB7E", bg: "#CF5800" },
  { color: "#00809D", bg: "#90C8D4" },
  { color: "#4F00B2", bg: "#D9BBFF" },
  { color: "#E8AB7E", bg: "#CF5800" },
];

export const TailwindColors = {
  BgBlue100: "bg-blue-100",
  BgBlue100Important: "!bg-blue-100",
};
