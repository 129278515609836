import CFWButton from "components/custom/CFWButton";
import MemberProgress from "./MemberProgress";
import { Modal } from "components/organisms/modal";
import tw from "twin.macro";

const MembersModal = ({ isOpen, setIsOpen, members }) => {
  return (
    <Modal
      open={isOpen}
      onOpenChange={setIsOpen}
      contentProps={{ css: tw`w-[550px]` }}
      title="Members Progress"
      header="Members Progress"
      body={
        <div className="w-full px-[24px] max-h-[400px] overflow-y-scroll">
          {members?.length > 0 ? (
            members?.map((userProgress, index) => (
              <MemberProgress
                name={userProgress.username}
                totalTasks={userProgress?.total_requirements}
                completedTasks={userProgress?.total_completed}
                key={index}
              />
            ))
          ) : (
            <p className="text-[13px] px-[8px] text-[#8D98A2]">No members assigned.</p>
          )}
        </div>
      }
      footer={
        <div className="w-full bg-[#ffffff] h-[65px] flex items-end">
          <CFWButton title="Done" onClick={() => setIsOpen(false)} />
        </div>
      }
    />
  );
};

export default MembersModal;
