import SpinnerCircle from "utils/Spinner/SpinnerCircle";
import { Merge, X } from "lucide-react";
import { useSelection } from "../SelectionContext";
import useExtractionOperations from "hook/useExtractionOperations";
import { useMergeRequirements } from "../hooks";
import { StepValue } from "../../types";
import { useAppSelector } from "store/storeTypes";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { YJS_OPERATIONS } from "const-values/yjs";

const BulkFloatingPillActions = () => {
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);

  const isRequirementsStep = extraction?.step === StepValue.Requirements;
  const { mergeRequirements, isLoading: isMergingRequirements } = useMergeRequirements();
  const { selectedBlocks = [], clearSelection } = useSelection();

  const allBlocksAreUnselected = selectedBlocks.every((block) => block.requirement.requirement.skipped);
  const allBlocksAreSelected = selectedBlocks.every((block) => !block.requirement.requirement.skipped);
  const allBlocksAreUnassigned = selectedBlocks.every(
    (block) => block.requirement.proposal_reference.section_id === ""
  );
  const { addAttribution, bulkUnassignExtractionRequirements, setBulkExtractionRequirementsSkipped } =
    useExtractionOperations();

  return (
    <div className="relative w-fit whitespace-nowrap select-none shadow-lg shadow-black/40 z-[10] rounded-md p-2 bg-black/85 h-[46px] backdrop-blur-lg bottom-6 left-1/2 -translate-x-1/2 flex flex-row items-center gap-2 animate-fadeIn duration-100">
      <div className="shrink-0 flex h-7 items-center">
        <div className="text-gray-100 h-[inherit] flex items-center px-2 text-xs border border-r-0 border-dashed border-gray-500 rounded-l">
          {selectedBlocks.length} chosen
        </div>
        <button
          onClick={clearSelection}
          className="h-[inherit] text-gray-200 text-base px-1.5 border border-dashed border-gray-500 rounded-r duration-100 hover:text-white hover:border-gray-100"
        >
          <X size={16} />
        </button>
      </div>
      {isRequirementsStep && selectedBlocks.length > 1 && (
        <>
          <div className="w-px bg-gray-500 h-[24px]" />

          <button
            onClick={async () => {
              if (!extraction?.id || isMergingRequirements) return;
              const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

              try {
                await mergeRequirements({ requirement_ids: selectedReqIds });
              } catch {}
            }}
            className="text-xs flex items-center gap-2 max-w-80 font-medium border px-2.5 py-1.5 border-gray-400 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100 hover:bg-black disabled:text-gray-500 disabled:cursor-not-allowed disabled:bg-transparent disabled:border-gray-500"
            disabled={isMergingRequirements}
          >
            {isMergingRequirements ? <SpinnerCircle className="h-3.5 w-3.5" /> : <Merge size={14} />}

            <div className="text-xs">Merge</div>
          </button>
        </>
      )}
      <div className="w-px bg-gray-500 h-[24px]" />
      <Tooltip
        content="Highlighted requirements are already unassigned."
        disabled={!allBlocksAreUnassigned}
        contentProps={{ style: { maxWidth: "600px" } }}
      >
        <button
          onClick={() => {
            if (!extraction?.id) return;
            const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

            bulkUnassignExtractionRequirements(extraction?.id, selectedReqIds);
            addAttribution(YJS_OPERATIONS.EXTRACTION.SET_REQUIREMENT_BULK_UNASSIGN);

            clearSelection?.();
          }}
          className="text-xs max-w-80 font-medium border px-2.5 py-1.5 border-gray-400 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100 hover:bg-black disabled:text-gray-500 disabled:cursor-not-allowed disabled:bg-transparent disabled:border-gray-500"
          disabled={allBlocksAreUnassigned}
        >
          Unassign
        </button>
      </Tooltip>
      <>
        <div className="w-px bg-gray-500 h-[24px]" />
        <Tooltip
          content="Highlighted requirements are already unselected."
          disabled={!allBlocksAreUnselected}
          contentProps={{ style: { maxWidth: "600px" } }}
        >
          <button
            onClick={() => {
              if (!extraction?.id) return;
              const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

              setBulkExtractionRequirementsSkipped(extraction.id, selectedReqIds, true);
              addAttribution(YJS_OPERATIONS.EXTRACTION.SET_REQUIREMENT_BULK_SKIPPED);

              clearSelection?.();
            }}
            className="text-xs max-w-80 font-medium border px-2.5 py-1.5 border-gray-400 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100 hover:bg-black disabled:text-gray-500 disabled:cursor-not-allowed disabled:bg-transparent disabled:border-gray-500"
            disabled={allBlocksAreUnselected}
          >
            Deselect
          </button>
        </Tooltip>
        <Tooltip
          content="Highlighted requirements are already selected."
          disabled={!allBlocksAreSelected}
          contentProps={{ style: { maxWidth: "600px" } }}
        >
          <button
            onClick={() => {
              if (!extraction?.id) return;
              const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

              setBulkExtractionRequirementsSkipped(extraction.id, selectedReqIds, false);
              addAttribution(YJS_OPERATIONS.EXTRACTION.SET_REQUIREMENT_BULK_SELECTED);

              clearSelection?.();
            }}
            className="text-xs truncate max-w-80 font-medium border px-2.5 py-1.5 border-gray-400 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100 hover:bg-black disabled:text-gray-500 disabled:cursor-not-allowed disabled:bg-transparent disabled:border-gray-500"
            disabled={allBlocksAreSelected}
          >
            Select
          </button>
        </Tooltip>
      </>
    </div>
  );
};

export default BulkFloatingPillActions;
