import { isEqual } from "lodash";
import {
  NodeViewContent,
  NodeViewProps,
  NodeViewRendererProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react";
import { Plus } from "lucide-react";
import { TableCell } from "@tiptap/extension-table-cell";

const useCellActions = (editor: NodeViewRendererProps["editor"], _getPos: boolean | (() => number)) => {
  const getPos = typeof _getPos === "function" ? _getPos : false;

  const addColumnBefore = () => {
    if (!getPos) return editor.chain().focus().addColumnBefore().run();
    const pos = getPos();
    return editor.chain().focus().setNodeSelection(pos).addColumnBefore().run();
  };

  const addColumnAfter = () => {
    if (!getPos) return editor.chain().focus().addColumnAfter().run();
    const pos = getPos();
    return editor.chain().focus().setNodeSelection(pos).addColumnAfter().run();
  };

  const addRowBefore = () => {
    return editor.chain().focus().addRowBefore().run();
  };

  const addRowAfter = () => {
    return editor.chain().focus().addRowAfter().run();
  };

  return {
    addColumnBefore,
    addColumnAfter,
    addRowAfter,
    addRowBefore,
  };
};

interface CellActionProps {
  type: "top" | "left" | "right" | "bottom";
  tooltipContent?: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const CellAction: React.FC<CellActionProps> = ({ type, className = "", children, onClick }) => {
  const positionStyles: Record<string, string> = {
    top: "absolute left-0 right-0 top-0 h-4 w-full",
    bottom: "absolute left-0 right-0 bottom-0 h-4 w-full",
    left: "absolute top-0 bottom-0 left-0 w-4 h-full",
    right: "absolute top-0 bottom-0 right-0 w-4 h-full",
  };

  const combinedClassName = `flex justify-center items-center gap-2 hover:bg-gray-100 opacity-0 hover:opacity-100 cursor-pointer ${positionStyles[type]} ${className}`;

  return (
    <div className={combinedClassName} onMouseEnter={(e) => e.stopPropagation()} onClick={onClick}>
      {children}
    </div>
  );
};

const CustomTableCellComponent: React.FC<NodeViewProps> = ({ editor, getPos }) => {
  const { addColumnBefore, addColumnAfter, addRowAfter, addRowBefore } = useCellActions(editor, getPos);
  const isEditable = editor.isEditable;

  const cellActions = !isEditable ? null : (
    <>
      <CellAction type="top" tooltipContent="Add Row Above" onClick={addRowBefore}>
        <Plus size={14} />
      </CellAction>
      <CellAction type="left" tooltipContent="Add Column Before" onClick={addColumnBefore}>
        <Plus size={14} />
      </CellAction>
      <CellAction type="bottom" tooltipContent="Add Row Below" onClick={addRowAfter}>
        <Plus size={14} />
      </CellAction>
      <CellAction type="right" tooltipContent="Add Column After" onClick={addColumnAfter}>
        <Plus size={14} />
      </CellAction>
    </>
  );
  return (
    <NodeViewWrapper as="div">
      {cellActions}
      <NodeViewContent as="div" className="p-3" />
    </NodeViewWrapper>
  );
};

export const CustomTableCell = TableCell.extend({
  addNodeView() {
    return ReactNodeViewRenderer(CustomTableCellComponent, {
      update: ({ updateProps, oldNode, newNode }) => {
        if (isEqual(oldNode.attrs, newNode.attrs)) {
          return false;
        }
        updateProps();
        return true;
      },
      as: "td",
      className: "tiptap-table-cell",
    });
  },
});
