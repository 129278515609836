/** @jsxImportSource @emotion/react */

import { BaseFile } from "types/FileStorage";
import { ComplianceMatrixRow } from "components/copilot/CopilotSchemaImmutableTypes";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { enqueue } from "store/reducers/requirementsSmartResponseReducer";
import { Files } from "lucide-react";
import { ResponseTolerance } from "types/Requirement";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { STRICTNESS_TO_META } from "const-values/Draft";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { useMutation } from "YJSProvider/createYJSContext";
import { useFlags } from "hook/useFlags";
import { uniq } from "lodash";
import { useNotification } from "context/notificationContext";
import { find, update } from "YJSProvider/LiveObjects";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useCallback, useEffect, useState } from "react";
import AttachmentsPopover from "components/molecules/attachments-popover";
import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip/Tooltip";
import tw from "twin.macro";
import useRequirementOperations from "hook/useRequirementOperations";
import { ResponseSpeed } from "components/molecules/response-quality-dropdown/types";
import ResponseQualityDropdown from "components/molecules/response-quality-dropdown";

type GenerateResponseActionsProps = {
  showStrictnessDropdown?: boolean;
  showSelectFileButton?: boolean;
  disabled: boolean;
  row: ComplianceMatrixRow;
  showSpeedDropdown?: boolean;
};

const GenerateResponseActions = ({
  showStrictnessDropdown = false,
  showSelectFileButton = false,
  disabled,
  row,
  showSpeedDropdown,
}: GenerateResponseActionsProps) => {
  const { is_response_generating, requirement, requirement_file_id_filters, writing_prompts, written_content } = row;
  const groupedAtlasRequirements = useAppSelector((root) => root.project.groupedAtlasRequirements);
  const dispatch = useAppDispatch();
  const { setToast } = useNotification();
  const { saveRequirementFileIdFilters } = useRequirementOperations();
  const [localFiles, setLocalFiles] = useState<string[]>(
    requirement_file_id_filters ? requirement_file_id_filters : []
  );

  const [strictness, setStrictness] = useState<ResponseTolerance>(row?.response_tolerance || ResponseTolerance.Strict);
  const [selectedSpeed, setSelectedSpeed] = useState<ResponseSpeed>(row?.response_speed || ResponseSpeed.Speed);

  const { currentUser } = useAppSelector((store) => store.auth);
  const StrictnessIcon = STRICTNESS_TO_META[strictness].icon;

  const hasTextContent = (written_content?.trim() || "").length > 0;

  const generateLabel = hasTextContent ? "Regenerate" : "Generate";
  const hasWritingPrompts = writing_prompts && writing_prompts?.length > 0;

  useEffect(() => {
    if (row?.response_speed) setSelectedSpeed(row?.response_speed);
  }, [row?.response_speed]);

  useEffect(() => {
    if (row?.response_tolerance) setStrictness(row?.response_tolerance);
  }, [row?.response_tolerance]);

  const flags = useFlags();

  const enqueueResponse = useMutation(
    ({ storage }) => {
      const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      if (!matrix) return;
      const complianceMatrixRow = find(
        matrix,
        (complianceMatrixRow) => complianceMatrixRow.get("requirement")?.get("id") === row.requirement?.id
      );

      const hasEmptyRequirement = !(
        complianceMatrixRow?.get("requirement")?.get("content")?.trim() ||
        complianceMatrixRow?.get("requirement")?.get("summarized_content")?.trim()
      );

      if (hasEmptyRequirement) {
        setToast.error({ msg: "Please add requirement content to generate a response." });
        return;
      }

      if (
        !complianceMatrixRow ||
        complianceMatrixRow?.get("is_response_in_queue") ||
        complianceMatrixRow?.get("is_response_generating") ||
        hasEmptyRequirement
      )
        return;

      update(complianceMatrixRow, { is_response_in_queue: true, auto_response_actor: currentUser?.id });

      dispatch(
        enqueue([
          {
            ...row,
            is_response_in_queue: true,
            auto_response_actor: currentUser?.id,
            response_tolerance: strictness,
            decompose_requirement: flags.qualityResponse,
            speed_optimized: selectedSpeed === ResponseSpeed.Speed && flags.qualityResponse,
            red_team: selectedSpeed === ResponseSpeed.Quality && flags.qualityResponse,
            hasAtlasResponse: !!groupedAtlasRequirements?.[row.requirement.id]?.response,
          },
        ])
      );
    },
    [row, dispatch, currentUser, strictness, groupedAtlasRequirements, flags.qualityResponse, selectedSpeed]
  );

  const handleSelectedFiles = useCallback(
    (checked: boolean, files: BaseFile[]) => {
      if (checked) {
        setLocalFiles((prev) => uniq([...prev, ...files.map(({ id }) => id)]));
      } else setLocalFiles((prev) => prev.filter((id) => !files.find((file) => file.id === id)));
    },
    [setLocalFiles]
  );

  const onFileSave = useCallback(
    (open: boolean) => {
      !open && saveRequirementFileIdFilters(requirement.id, localFiles);
    },
    [saveRequirementFileIdFilters, requirement.id, localFiles]
  );

  const handleClear = useCallback(
    (files: BaseFile[]) =>
      setLocalFiles((prev) => prev.filter((prevFileId) => !files.find((file) => file.id === prevFileId))),
    []
  );

  return (
    <div className="flex items-center justify-end gap-2">
      {showSelectFileButton && (
        <AttachmentsPopover
          handleSelectedFiles={handleSelectedFiles}
          onClear={handleClear}
          onOpenChange={onFileSave}
          selectedFiles={localFiles}
        >
          <div>
            <Tooltip
              content="File selection is not supported while using writing directions. Please confirm in advanced settings."
              disabled={!hasWritingPrompts}
            >
              <button
                disabled={disabled || hasWritingPrompts}
                className="relative flex items-center gap-1 bg-slate-200 text-gray-mid rounded border-[1px] border-transparent shadow-sm text-xs px-2 py-1 duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-gray-mid disabled:hover:bg-slate-200"
              >
                <Files size={12} />
                Select Files
                {!!localFiles.length && !hasWritingPrompts && (
                  <div
                    className="bg-action text-white font-medium shadow-sharp-full rounded-full flex justify-center items-center absolute -top-1.5 -right-2"
                    css={[
                      String(localFiles?.length || 0).length > 2 ? tw`w-4 h-4 text-[8px]` : tw`w-3.5 h-3.5 text-[9px]`,
                    ]}
                  >
                    {localFiles.length}
                  </div>
                )}
              </button>
            </Tooltip>
          </div>
        </AttachmentsPopover>
      )}

      {showStrictnessDropdown && (
        <DropdownMenu
          disabled={disabled}
          items={[
            {
              key: 1,
              label: (
                <div>
                  <div className="text-sm pb-1">{STRICTNESS_TO_META[ResponseTolerance.Strict].label}</div>
                  <div className="text-[11.5px] text-gray-text">
                    {STRICTNESS_TO_META[ResponseTolerance.Strict].text}
                  </div>
                </div>
              ),
              selected: strictness === ResponseTolerance.Strict,
              value: ResponseTolerance.Strict,
              onSelect: () => setStrictness(ResponseTolerance.Strict),
            },
            {
              key: 2,
              label: (
                <div>
                  <div className="text-sm pb-1">{STRICTNESS_TO_META[ResponseTolerance.Moderate].label}</div>
                  <div className="text-[11.5px] text-gray-text">
                    {STRICTNESS_TO_META[ResponseTolerance.Moderate].text}
                  </div>
                </div>
              ),
              selected: strictness === ResponseTolerance.Moderate,
              value: ResponseTolerance.Moderate,
              onSelect: () => setStrictness(ResponseTolerance.Moderate),
            },
            {
              key: 3,
              label: (
                <div>
                  <div className="text-sm pb-1">{STRICTNESS_TO_META[ResponseTolerance.Flexible].label}</div>
                  <div className="text-[11.5px] text-gray-text">
                    {STRICTNESS_TO_META[ResponseTolerance.Flexible].text}
                  </div>
                </div>
              ),
              selected: strictness === ResponseTolerance.Flexible,
              value: ResponseTolerance.Flexible,
              onSelect: () => setStrictness(ResponseTolerance.Flexible),
            },
          ]}
        >
          <div
            className="flex items-center gap-1 bg-slate-200 text-slate-600 rounded shadow-sm text-xs px-2 py-1 duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200"
            css={[disabled && tw`text-zinc-300 pointer-events-none`]}
          >
            {<StrictnessIcon className="w-3.5 h-3.5" />}
            {STRICTNESS_TO_META[strictness].label}
          </div>
        </DropdownMenu>
      )}

      {flags.qualityResponse && showSpeedDropdown && (
        <ResponseQualityDropdown onSpeedSelect={setSelectedSpeed} disabled={disabled} selectedSpeed={selectedSpeed} />
      )}

      <button
        className="text-action bg-action-light text-xs px-2 py-1 flex duration-150 items-center gap-1 hover:text-action-hover rounded shadow-sm border-[1px] border-[#CBD5E1] disabled:!text-[#91a0d3]"
        css={[disabled && tw`!text-zinc-300 cursor-not-allowed`]}
        disabled={disabled}
        onClick={() => {
          if (hasTextContent) {
            triggerConfirm({
              proceedLabel: "Confirm",
              header: `Are you sure you want to ${generateLabel.toLowerCase()}?`,
              body: "This will clear all of the response text.",
            }).then((proceed) => {
              if (proceed) {
                enqueueResponse();
              }
            });
          } else enqueueResponse();
        }}
      >
        <Icon
          name="Generate"
          css={[
            is_response_generating && {
              animation: "rotateAnimation 0.5s infinite linear",
            },
          ]}
        />{" "}
        {is_response_generating ? (
          <span>
            Generating
            <span className="loading-ellipsis" />
          </span>
        ) : (
          generateLabel
        )}
      </button>
    </div>
  );
};

export default GenerateResponseActions;
