import { useAppSelector } from "store/storeTypes";
import Icon from "components/atoms/icons/Icon";
import useExtractionOperations from "hook/useExtractionOperations";

const TemplateManagerEmptyState = () => {
  const { addNewVolume } = useExtractionOperations();
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);

  const handleAddNewVolume = () => {
    if (extraction?.id) addNewVolume(extraction.id);
  };

  return (
    <div className="px-8 h-full flex flex-col items-center justify-center">
      <div className="text-center mt-5">
        <h1 className="font-bold text-xl mb-1">No Template Added</h1>
        <span className="text-gray-text text-sm mt-3">
          Start by adding a volume or select a template in the select template step
        </span>
      </div>
      <button
        className="bg-gray-200 flex min-w-[40px] my-5 min-h-[26px] text-gray-600 gap-1 py-1.5 pr-3.5 pl-2 text-sm items-center rounded-md"
        onClick={() => handleAddNewVolume()}
      >
        <Icon name="Plus" className="w-4 h-4" />
        <span>Volume</span>
      </button>
    </div>
  );
};

export default TemplateManagerEmptyState;
