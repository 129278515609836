import { FeatureFlagSet } from "types/FeatureFlagSet";

export const STATIC_FEATURE_FLAG_MAP: FeatureFlagSet = {
  newFeatureBanner: {},
  comments: true,
  instantDraft: false,
  topBannerV2: { bannerCopy: "" },
  cmTemplates: true,
  redTeam: false,
  qualityResponse: true,
  capture: true,
  instantDraftFromGeneration: true,
  reviewEngine: true,
  emergencyYjs: false,
  askAiExtraction: true,
};
