export type StreamStopMsgData = {
  event: StreamEvent.StreamStop;
  type?: StreamEventType;
  reason?: string;
};

export enum StreamEvent {
  StreamStop = "streamstop",
  StreamRestart = "streamrestart",
  Payload = "payload",
}

export enum StreamEventType {
  NoSources = "no_sources",
  Repetition = "repetition",
}
