import { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import FallbackComponent from "./FallbackComponent";
import * as Sentry from "@sentry/browser";

const ErrorBoundaryWrapper = ({ children }: PropsWithChildren<{}>) => {
  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={(error, info) => {
        const eventId = Sentry.captureException(error, {
          level: "fatal",
          extra: { componentStack: info?.componentStack },
        });

        Sentry.showReportDialog({
          eventId,
          title: "Feedback Form",
          subtitle: "Provide additional details to help us fix this issue.",
          subtitle2: "Your feedback is valuable to us.",
          labelComments: "What were you doing when the error occurred?",
          labelSubmit: "Send Feedback",
        });
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
