/** @jsxImportSource @emotion/react */

import Icon from "components/atoms/icons/Icon";
import { Button } from "components/editor/components";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import tw from "twin.macro";
import cloudUpload from "Assets/cloud-upload.svg";
import { formatBytes } from "utils/helpers";
import { getWordCount, sliceByWordCount } from "utils/getWordCount";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { extractDoc, extractText, setAttachments } from "store/reducers/newProjectReducer";
import { ArrowUp } from "lucide-react";
import { MAX_ATTACHMENT_SIZE } from "const-values/Project";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { useNotification } from "context/notificationContext";

const MAX_MANUAL_IMPORT_TEXT = 2000;
const LOADING_MSGS = ["Analyzing", "Scanning", "Importing"];

enum ImportType {
  Upload = "Import From Document",
  CopyPaste = "Import From Text",
}

const SmartImport = () => {
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);
  const [manualImportText, setManualImportText] = useState("");
  const [loadingMsg, setLoadingMsg] = useState(LOADING_MSGS[0]);
  const [uploadType, setUploadType] = useState<ImportType>(ImportType.Upload);
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const { isExtractLoading } = useAppSelector((root) => root.newProject);
  useAnimateLoadingMsg(isExtractLoading, 2500, LOADING_MSGS, (msg) => setLoadingMsg(msg));

  const items = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2">
            <Icon name="Upload" className="w-4 h-4" /> Import From Document
          </div>
        ),
        value: ImportType.Upload,
        onSelect: () => setUploadType(ImportType.Upload),
      },
      {
        key: 2,
        label: (
          <div className="flex items-center gap-2">
            <Icon name="Copy" className="w-4 h-4" /> Import From Text
          </div>
        ),
        value: ImportType.CopyPaste,
        onSelect: () => setUploadType(ImportType.CopyPaste),
      },
    ],
    []
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles && acceptedFiles?.length > 0) {
        const file = acceptedFiles[0];
        if (isExtractLoading) return;

        try {
          setLoadingMsg("Uploading");
          let formData = new FormData();
          formData.append("file", file);
          await dispatch(extractDoc(formData));
          await dispatch(setAttachments(acceptedFiles));
        } catch {
          setToast.error({
            title: "Failed to import",
            msg: "We were unable to import the project information due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
          });
        }
      }
    },
    [dispatch, isExtractLoading, setToast]
  );

  const dropValidator = (file: File) => {
    if (file?.size > MAX_ATTACHMENT_SIZE) {
      setToast.error({
        title: "Document size not supported",
        msg:
          file?.name +
          `size is too large. We do not support document sizes larger than ${formatBytes(
            MAX_ATTACHMENT_SIZE
          )}. Please try again with a smaller document or contact support@vultron.ai for assistance.`,
      });
      return {
        code: "file-too-large",
        message: `Document is larger than ${formatBytes(MAX_ATTACHMENT_SIZE)}`,
      };
    }

    return null;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: MAX_ATTACHMENT_SIZE,
    validator: dropValidator,
    accept: {
      "text/plain": [".txt"],
      "text/pdf": [".pdf"],
      "text/doc": [".docx"],
      "text/excel": [".xlsx"],
      "text/powerpoint": [".pptx"],
    },
    noClick: false,
    maxFiles: 1,
    multiple: false,
  });

  const extractProjectText = useCallback(async () => {
    if (!manualImportText.trim() || isExtractLoading) return;
    try {
      setLoadingMsg(LOADING_MSGS[0]);
      await dispatch(extractText({ text: manualImportText }));
    } catch {
      setToast.error({
        title: "Failed to import",
        msg: "We were unable to import the project information due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
      });
    }
  }, [dispatch, isExtractLoading, manualImportText, setToast]);

  const words = useMemo(() => getWordCount(manualImportText), [manualImportText]);

  return (
    <div className="px-4">
      <div className="flex pl-3 items-center gap-1 font-bold py-2">
        Import <div className="font-normal text-gray-lightest text-xs ml-0.5 mt-0.5">(Optional)</div>
      </div>
      <div className="font-normal pl-3 text-gray-500 text-xs">
        Vultron will automatically populate the project information from your imported document.
      </div>
      <div className="p-4 pt-4 pl-2 pr-0 flex flex-col gap-1 pb-4" ref={setContentRef}>
        <DropdownMenu
          modal
          disabled={isExtractLoading}
          items={items}
          triggerProps={{ className: "w-fit rounded-lg disabled:opacity-50" }}
          portalProps={{ container: contentRef }}
          contentProps={{ align: "start" }}
        >
          <Button
            className="!border-neutral-300 !pr-2.5 !text-stone-900 hover:!bg-neutral-100 group-disabled:bg-gray-200 group-disabled:text-slate-400 group-disabled:duration-0"
            variant="outline"
            size="md"
          >
            {uploadType || "Import Method"}
            <Icon name="CarrotDown" className="ml-2" />
          </Button>
        </DropdownMenu>
        {uploadType === ImportType.CopyPaste && (
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <div
                className="text-xs flex justify-end text-gray-400"
                css={[words >= MAX_MANUAL_IMPORT_TEXT && tw`text-red-500`]}
              >
                {words}/{MAX_MANUAL_IMPORT_TEXT} words
              </div>
              <div className="relative h-54">
                <textarea
                  rows={5}
                  value={manualImportText}
                  onChange={(e) => {
                    const count = getWordCount(e.target.value);
                    if (count > MAX_MANUAL_IMPORT_TEXT) {
                      if (
                        // @ts-ignore
                        e.nativeEvent.inputType === "insertFromPaste" &&
                        words < MAX_MANUAL_IMPORT_TEXT
                      )
                        setManualImportText(sliceByWordCount(e.target.value, MAX_MANUAL_IMPORT_TEXT));
                      return;
                    }
                    setManualImportText(e.target.value);
                  }}
                  placeholder="Include the background, scope, objectives, and all relevant information for optimal results"
                  className="align-top w-full h-[114px] text-sm py-2 px-2.5 rounded-md border border-gray-light resize-none outline-none"
                />
                {isExtractLoading && (
                  <div className="flex flex-col rounded-md absolute w-full top-0 bottom-0 justify-center items-center bg-[rgba(255,255,255,0.8)]">
                    <ArrowUp size={40} className="text-action" />
                    <div className="font-semibold pointer-events-none mt-2">
                      {loadingMsg}
                      <span className="loading-ellipsis" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Button
              variant="primary"
              size="sm"
              className="w-fit ml-auto"
              onClick={() => extractProjectText()}
              disabled={!manualImportText.trim() || isExtractLoading}
              loading={isExtractLoading}
            >
              Import
            </Button>
          </div>
        )}
        {uploadType === ImportType.Upload && (
          <div
            {...getRootProps()}
            className="p-3 w-full cursor-pointer relative border-gray-300 border-dashed border-2 mt-3 justify-center flex flex-col items-center rounded-lg bg-[#fafafa] h-[162px]"
            css={[isExtractLoading && tw`!pointer-events-none`]}
          >
            {isExtractLoading ? (
              <>
                <ArrowUp size={40} className="text-action" />
                <div className="font-semibold pointer-events-none mt-2">
                  {loadingMsg}
                  <span className="loading-ellipsis" />
                </div>
              </>
            ) : (
              <>
                <input multiple {...getInputProps()} />
                {isDragActive && (
                  <div className="absolute bg-gray-300 flex justify-center items-center text-gray-700 top-0 left-0 h-full w-full z-[2] bg-dark1 bg-opacity-50" />
                )}
                <img src={cloudUpload} alt="" className="h-9 w-9" />
                <span className="font-semibold pointer-events-none">Drop or Select File</span>
                <span className="text-gray-text pointer-events-none text-sm">
                  We accept pdf, docx, txt, xlsx, and pptx files
                </span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SmartImport;
