import { getWorkspaceDetails } from "api/api.jsx";
import { useCallback } from "react";
import { useLocalStorage } from "hook/useLocalStorage";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

// Create and Install Session Replay Plugin
const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1.0 });
amplitude.add(sessionReplayTracking);
// Initialize Amplitude with your project's API key
amplitude.init(process.env.REACT_APP_AMPLITUDE_PROD);

export const useTrackUserMetric = () => {
  const [searchParams] = useSearchParams();
  const { localValue: workspaceId } = useLocalStorage("vultron_workspace_id", "");
  const { myWorkSpaces } = useSelector((store) => store.auth);
  const projectId = searchParams.get("id")?.toLocaleLowerCase();
  const isProduction = process.env["NODE_ENV"] === "production";

  const trackUserEvent = useCallback(
    async (eventInput, properties = {}, userProperties = {}) => {
      if (!isProduction || myWorkSpaces?.email?.endsWith("@synthetics.dtdg.co")) {
        return;
      }

      try {
        const response = await getWorkspaceDetails();
        const companyName = response?.data?.company_name || "Company Name Not Available";
        amplitude.setGroup("company_name", companyName);
      } catch {}

      amplitude.setGroup("workspace_ids", workspaceId);

      if (projectId) {
        amplitude.setGroup("project_ids", projectId);
      }

      if (Object.keys(userProperties).length > 0) {
        const identify = new amplitude.Identify();
        for (const [key, value] of Object.entries(userProperties)) {
          identify.set(key, value);
        }
        amplitude.identify(identify);
      }

      const eventProperties = {
        ...properties,
      };
      amplitude.track(eventInput, eventProperties);
    },
    [workspaceId, projectId, myWorkSpaces?.email, isProduction]
  );

  return trackUserEvent;
};
