/** @jsxImportSource @emotion/react */

import { Modal } from "../../organisms/modal";
import tw from "twin.macro";
import { ComponentProps, useEffect, useMemo, useRef } from "react";
import { useAppSelector } from "store/storeTypes";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import PreviewProposalSidebar from "./PreviewProposalSidebar";
import PreviewDocument from "./PreviewDocument";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { useDetectPathChange, usePreviewProposalConfig } from "./hooks";
import { getWordCount } from "utils/getWordCount";
import { useTrackUserMetric } from "utils/metrics";
import { DisplayOption } from "store/reducers/draft/previewProposalReducer";
import { useStorage } from "YJSProvider/createYJSContext";
import { isEqual } from "lodash";
import { Section, Storage, Volume } from "components/copilot/CopilotSchemaImmutableTypes";

export interface PreviewProposalModalProps extends Pick<ComponentProps<typeof Modal>, "open"> {}

const PreviewProposalModal = (props: PreviewProposalModalProps) => {
  const { previewProposalConfig } = usePreviewProposalConfig();
  useDetectPathChange();
  const activeDraftId = useAppSelector((store) => store.previewProposalState.activeDraftId);
  const volume = useStorage(
    (storage) => (storage.framework?.volumes as Volume[] | undefined)?.find((vol) => vol.id === activeDraftId),
    isEqual
  );

  const volumeRequirements = useStorage(
    (storage) =>
      !props.open
        ? []
        : ((storage.compliance_matrix as Storage["compliance_matrix"]) || []).filter(
            (row) =>
              !row.requirement.skipped &&
              (!!row.written_content?.trim() ||
                (!row.written_content?.trim() &&
                  (!!row.requirement.content ||
                    !!row.requirement.summarized_content ||
                    !!row.requirement.generated_heading))) &&
              row.proposal_reference.volume_id === activeDraftId
          ),
    isEqual
  );
  const { sections } = volume || {};

  const sortedSections = useMemo(() => {
    return (
      sections?.reduce<FormattedSection[]>((acc, section) => {
        if (!section.parent_id) {
          const subsections = sections.filter(({ parent_id }) => parent_id === section.id);
          return [...acc, { ...section, subsections }];
        }

        return acc;
      }, []) || []
    );
  }, [sections]);

  const sortedRequirements = useMemo(
    () =>
      [...volumeRequirements].sort((a, b) => (a.requirement.section_order || 0) - (b.requirement.section_order || 0)),
    [volumeRequirements]
  );

  const requirementsBySection = useMemo(
    () =>
      sortedSections.reduce<
        {
          section: Section;
          requirements: Storage["compliance_matrix"];
          isSubsection?: boolean;
        }[]
      >((acc, sec) => {
        const { subsections, ...section } = sec;
        const sectionReqs = sortedRequirements.filter((req) => req.proposal_reference.section_id === sec.id);
        const subsectionObjs =
          subsections?.map((subsection) => {
            const subsectionReqs = sortedRequirements.filter(
              (req) => req.proposal_reference.section_id === subsection.id
            );

            return {
              section: subsection,
              isSubsection: true,
              requirements: subsectionReqs,
            };
          }) || [];

        const sectionObj = {
          section: section,
          requirements: sectionReqs,
        };
        return [...acc, sectionObj, ...subsectionObjs];
      }, []),
    [sortedRequirements, sortedSections]
  );

  const wordCount = useMemo(() => {
    const includeSources = previewProposalConfig?.includeSourceDocuments;

    let docString = requirementsBySection.reduce<string>((acc, { section, requirements }) => {
      acc += `${section.title} `;

      requirements.forEach((req) => {
        const sectionRequirementConfig = previewProposalConfig?.sectionFormats?.find(
          (format) => format.id === req.proposal_reference.section_id
        );

        let reqResponse = req.written_content || "";
        const responseSources = req.response_sources;

        responseSources?.forEach(({ used_file_contents, ...source }) => {
          used_file_contents.forEach(({ requirement_source_citations, content }) => {
            const escapedSource = requirement_source_citations.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");

            if (!includeSources) {
              reqResponse = reqResponse.replace(new RegExp("\\s?" + escapedSource, "g"), "");
            }
          });
        });

        switch (sectionRequirementConfig?.displayOption) {
          case DisplayOption.DontInclude:
            acc += `${reqResponse} `;
            break;
          case DisplayOption.FullRequirement:
            acc += `${req.requirement.content || req.requirement.summarized_content} ${reqResponse} `;
            break;
          case DisplayOption.Heading:
            acc += `${req.requirement.generated_heading} ${reqResponse} `;
            break;
          default:
            acc += `${reqResponse} `;
            break;
        }
      });

      return acc;
    }, "");

    return getWordCount(docString);
  }, [previewProposalConfig, requirementsBySection]);

  // const totalPageCount = Math.round((wordCount / 400 + Number.EPSILON) * 100) / 100;
  const firstUpdate = useRef(true);
  const trackUserEvent = useTrackUserMetric();
  useEffect(() => {
    if (wordCount > 0 && previewProposalConfig && firstUpdate.current && props.open) {
      trackUserEvent("Drafts: Draft Previewed", {
        volume_id: activeDraftId,
        sections_count: volumeRequirements.length,
        word_count: wordCount,
      });
      firstUpdate.current = false;
    }
    if (!props.open) {
      firstUpdate.current = true;
    }
  }, [wordCount, props.open, trackUserEvent, activeDraftId, volumeRequirements.length, previewProposalConfig]);

  return (
    <Modal
      title="Preview proposal dialog"
      hideClose
      contentProps={{
        onPointerDownOutside: (e) => e.preventDefault(),
        css: tw`p-0 rounded-none !w-screen !h-screen !min-w-[100vw] !min-h-[100vh]`,
      }}
      bodyProps={tw`m-0`}
      body={
        <PanelGroup direction="horizontal">
          <Panel defaultSize={30} minSize={22} maxSize={44}>
            <PreviewProposalSidebar wordCount={wordCount} volume={volume} sortedSections={sortedSections} />
          </Panel>
          <PanelResizeHandle className="z-[1] relative group flex justify-center">
            <div className="w-px h-full bg-gray-800 delay-300 duration-150 group-hover:bg-slate-600 group-hover:scale-x-[2.5]" />
          </PanelResizeHandle>
          <Panel defaultSize={70}>
            <PreviewDocument
              requirementsBySection={requirementsBySection}
              sortedSections={sortedSections}
              volume={volume}
              volumeRequirements={volumeRequirements}
            />
          </Panel>
        </PanelGroup>
      }
      {...props}
    />
  );
};

export default PreviewProposalModal;
