/** @jsxImportSource @emotion/react */

import { WinTheme } from "components/copilot/CopilotSchemaImmutableTypes";
import ClickAwayListener from "helpers/ClickAwayListener";
import { useCallback } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { setAssistantPrompt } from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useTrackUserMetric } from "utils/metrics";
import { getWordCount } from "utils/getWordCount";
import { useStorage } from "YJSProvider/createYJSContext";
import { CopilotPresencePage } from "types/Presence";
import { useFlags } from "hook/useFlags";

interface Props {
  setWinThemesOpen: (val: boolean) => void;
}

const WinthemesPopover = ({ setWinThemesOpen }: Props) => {
  const flags = useFlags();
  const winThemes = (useStorage((root) => root.win_themes) as WinTheme[]) || [];
  const { prompt } = useAppSelector((root) => root.writingAssistant);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id")?.toLocaleLowerCase();
  const trackUserEvent = useTrackUserMetric();

  const handleSelectWinTheme = useCallback(
    (winTheme: WinTheme) => {
      const text = `Apply the following win theme to the text below: \n\nWin Theme: ${winTheme.content}\n\nText: ${prompt}`;
      dispatch(setAssistantPrompt(text));
      setWinThemesOpen(false);
      trackUserEvent("Chat: Win Theme Imported", {
        word_count: getWordCount(winTheme.content),
        win_theme_id: winTheme.id,
      });
    },
    [dispatch, prompt, setWinThemesOpen, trackUserEvent]
  );

  return (
    <div className="left-[-2px] right-[-2px] z-[100] bg-white shadow rounded-md border border-zinc-200 absolute bottom-[50px] text-gray-500 text-sm max-h-[40vh] overflow-y-auto flex flex-col gap-y-4 px-2 py-4">
      <ClickAwayListener
        onClickAway={(e) => {
          e.stopPropagation();
          setWinThemesOpen(false);
        }}
      >
        {winThemes.map((winTheme: WinTheme, index) => (
          <div
            key={winTheme.id}
            className="flex justify-between items-center bg-white hover:bg-[#f7f7f7] cursor-pointer p-2 rounded-md"
            onClick={() => handleSelectWinTheme(winTheme)}
          >
            <div className="flex flex-col gap-1">
              <div className="text-xs text-black font-semibold">{`Win Theme ${index + 1}`}</div>
              <div className="text-sm">{winTheme.content}</div>
            </div>
          </div>
        ))}
        {!winThemes.length && (
          <div className="min-h-[100px] w-full flex flex-col justify-center items-center text-sm text-gray-500 gap-2">
            <div>No win themes found.</div>
            <div>
              {flags.capture ? (
                <span>
                  Head to the{" "}
                  <Link
                    to={`/dashboard/contracts/details/capture?tab=${CopilotPresencePage.Capture}&id=${internalContractId}`}
                    className="text-action font-bold"
                    onClick={() => setWinThemesOpen(false)}
                  >
                    Capture tab
                  </Link>{" "}
                  to add win themes.
                </span>
              ) : (
                <span>
                  Head to the{" "}
                  <Link
                    to={`/dashboard/contracts/details?tab=${CopilotPresencePage.Project}&id=${internalContractId}`}
                    className="text-action font-bold"
                    onClick={() => setWinThemesOpen(false)}
                  >
                    Project tab
                  </Link>{" "}
                  to add win themes.
                </span>
              )}
            </div>
          </div>
        )}
      </ClickAwayListener>
    </div>
  );
};

export default WinthemesPopover;
