/** @jsxImportSource @emotion/react */

import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { WritingAssistantConversation } from "components/copilot/writing-assistant-drawer";
import WritingAssistantInput from "components/copilot/writing-assistant-drawer/writing-assistant-input";
import CopilotActionsOptions from "./CopilotActionsOptions";
import { ContentSearchContent } from "../Framework/content-search-drawer";
import { ScreenSpinner } from "utils/icons";
import RequirementDetails from "components/organisms/requirement-details";
import { toggleRequirements } from "store/reducers/copilot/copilotDrawerReducer";
import { setActiveRequirementId } from "store/reducers/copilot/requirementsReducer";
import Icon from "components/atoms/icons/Icon";
import { RequirementsDrawerContent } from "../Framework/requirements-drawer/RequirementsDrawer";
import "./styles.css";
import { useStatus } from "YJSProvider/createYJSContext";
import { CommentsPanel } from "components/Comments/components/CommentsPanel";
import { useParams, useSearchParams } from "react-router-dom";
import { useFlags } from "hook/useFlags";
import { ThreadContext } from "components/Comments/types";

const CopilotActionsPanel = () => {
  const flags = useFlags();
  const { comments: shouldEnableComments } = flags;
  const dispatch = useAppDispatch();
  const { open, assistantOpen, contentSearchOpen, requirementsOpen, requirementDetailsOpen, commentsOpen } =
    useAppSelector((root) => root.copilotDrawer);
  const yjsStatus = useStatus();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id")?.toLocaleLowerCase();
  const referenceId = searchParams.get("docId")?.toLocaleLowerCase() || params.volumeId;
  const commentsContext = params.volumeId ? ThreadContext.DRAFT : ThreadContext.PROPOSAL;

  return (
    <div
      className="bg-[#f8f9fa] pl-0 opacity-0 h-full w-0 duration-150"
      css={[
        open && tw`relative w-[448px] 2xl:w-[513px] opacity-100`,
        !open && tw`relative [animation-delay: 160ms] pointer-events-none animate-delayHide`,
      ]}
    >
      <div className="relative flex flex-col h-full items-center bg-white overflow-hidden">
        {yjsStatus !== "connected" && (
          <div className="p-7 top-0 left-0 right-0 bottom-0 absolute bg-[#5f55550e] z-[999999999] backdrop-blur-[2px] my-auto flex items-center justify-center">
            <ScreenSpinner />
          </div>
        )}
        <div className="flex w-full items-center justify-start gap-2 px-3 min-h-[46px] h-[46px] bg-white">
          <CopilotActionsOptions />
        </div>
        {requirementDetailsOpen && (
          <div className="w-full text-base font-semibold text-gray-600 px-3 py-2">
            <div className="flex gap-1 items-center">
              <button
                className="!h-5 !w-5 text-inherit hover:text-gray-darkest"
                onClick={() => {
                  dispatch(toggleRequirements(true));
                  dispatch(setActiveRequirementId(null));
                }}
              >
                <Icon name="ArrowLeft" className="h-5 w-5" />
              </button>
              <span>Requirements</span>
            </div>
          </div>
        )}
        {assistantOpen && (
          <div className="h-[calc(100%-46px)] w-full flex flex-col border-t border-gray-light">
            <WritingAssistantConversation />
            <WritingAssistantInput />
          </div>
        )}
        {contentSearchOpen && (
          <div className="h-[calc(100%-46px)] w-full flex flex-col pt-4 border-t border-gray-light">
            <ContentSearchContent />
          </div>
        )}
        {(requirementsOpen || requirementDetailsOpen) && (
          <div
            className="relative h-[calc(100%-46px)] w-full flex flex-col pt-4 border-t border-gray-light"
            css={[requirementDetailsOpen && tw`h-[calc(100%-94px)]`]}
          >
            <RequirementsDrawerContent />
            {requirementDetailsOpen && <RequirementDetails />}
          </div>
        )}
        {shouldEnableComments && commentsOpen && internalContractId && referenceId && (
          <CommentsPanel internalContractId={internalContractId} referenceId={referenceId} context={commentsContext} />
        )}
      </div>
    </div>
  );
};

export default CopilotActionsPanel;
