import { useMutation } from "utils/yjs-configs/ai-assistant/yjs.config";
import { AIAssistant, AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import { find, findIndex, LiveList, update } from "YJSProvider/LiveObjects";
import { createAIAssistantSession } from "utils/Liveblocks/AIAssistant";
import { useNavigate } from "react-router-dom";

const useAIAssistantOperations = () => {
  const navigate = useNavigate();

  const deleteMessageBlock = useMutation(({ storage }, sessionId: string, blockId: string) => {
    const aiAssistant = storage.get("ai_assistant");
    const sessions = aiAssistant?.get("sessions") as AIAssistant["sessions"];

    if (!sessions) return;

    const foundSessionConversation: AIAssistantSession["conversation"] | undefined = find(
      sessions,
      (session) => session.get("id") === sessionId
    )?.get("conversation");

    if (!foundSessionConversation) return;
    const foundBlockIdx = findIndex(foundSessionConversation, (block) => block.get("id") === blockId);

    if (typeof foundBlockIdx !== "number" || foundBlockIdx === -1) return;

    foundSessionConversation?.delete(foundBlockIdx);
  }, []);

  const archiveSession = useMutation(({ storage }, sessionId: string) => {
    const aiAssistant = storage.get("ai_assistant");
    const sessions = aiAssistant?.get("sessions") as AIAssistant["sessions"] | undefined;

    if (!sessions) return;

    const foundSessionIdx = findIndex(sessions, (session) => session.get("id") === sessionId);
    const foundSession = sessions?.get(foundSessionIdx);

    if (!foundSession) return;

    const clonedSession = foundSession.clone();
    const archivedSessions = aiAssistant.get("archived_sessions");

    if (!archivedSessions) aiAssistant.set("archived_sessions", new LiveList([]));

    aiAssistant.get("archived_sessions")?.push([clonedSession]);
    sessions.delete(foundSessionIdx);
  }, []);

  const createSession = useMutation(
    ({ storage }, sessionName?: string) => {
      const liveSessions = storage.get("ai_assistant")?.get("sessions") as AIAssistant["sessions"];

      const newSession = createAIAssistantSession({ name: sessionName?.slice(0, 150) });
      liveSessions.push([newSession]);

      navigate(`/dashboard/ai-assistant/${newSession.get("id")}`);

      return newSession;
    },
    [navigate]
  );

  const unarchiveSession = useMutation(({ storage }, sessionId: string) => {
    const aiAssistant = storage.get("ai_assistant");
    const archivedSessions = aiAssistant?.get("archived_sessions") as AIAssistant["archived_sessions"] | undefined;

    if (!archivedSessions) return;

    const foundSessionIdx = findIndex(archivedSessions, (session) => session.get("id") === sessionId);
    const foundSession = archivedSessions?.get(foundSessionIdx);

    if (!foundSession) return;

    const clonedSession = foundSession.clone();

    aiAssistant.get("sessions")?.push([clonedSession]);
    archivedSessions.delete(foundSessionIdx);
  }, []);

  const updateSession = useMutation(({ storage }, sessionId: string, properties: Partial<AIAssistantSession>) => {
    const aiAssistant = storage.get("ai_assistant");
    const sessions = aiAssistant?.get("sessions") as AIAssistant["sessions"] | undefined;

    if (!sessions) return;

    const foundSession = find(sessions, (session) => session.get("id") === sessionId);

    if (!foundSession) return;

    update(foundSession, { ...properties, updated_at: new Date().toISOString() });
  }, []);

  const deleteSession = useMutation(({ storage }, sessionId: string) => {
    const aiAssistant = storage.get("ai_assistant");
    const sessions = aiAssistant?.get("sessions") as AIAssistant["sessions"] | undefined;

    if (!sessions) return;

    const foundSessionIdx = findIndex(sessions, (session) => session.get("id") === sessionId);
    const foundSession = sessions.get(foundSessionIdx);

    if (!foundSession) return;

    sessions.delete(foundSessionIdx);
  }, []);

  const deleteArchivedSession = useMutation(({ storage }, sessionId: string) => {
    const aiAssistant = storage.get("ai_assistant");
    const sessions = aiAssistant?.get("archived_sessions") as AIAssistant["archived_sessions"] | undefined;

    if (!sessions) return;

    const foundSessionIdx = findIndex(sessions, (session) => session.get("id") === sessionId);
    const foundSession = sessions.get(foundSessionIdx);

    if (!foundSession) return;

    sessions.delete(foundSessionIdx);
  }, []);

  return {
    updateSession,
    unarchiveSession,
    deleteMessageBlock,
    archiveSession,
    deleteSession,
    deleteArchivedSession,
    createSession,
  };
};

export default useAIAssistantOperations;
