import { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { Button } from "components/editor/components";
import Input from "components/molecules/input";
import { Modal } from "components/organisms/modal";
import { EMPTY_SHEET } from "const-values/Sheets";
import useSheetOperations from "hook/useSheetOperations";
import { ComponentProps, useEffect, useMemo, useState } from "react";
import tw from "twin.macro";

interface Props extends Pick<ComponentProps<typeof Modal>, "open" | "onOpenChange"> {
  sheet: Sheet;
  sheets: Sheet[];
}

const EditSheetModal = ({ sheet, sheets, ...props }: Props) => {
  const [sheetName, setSheetName] = useState("");
  const { updateSheet } = useSheetOperations();

  useEffect(() => {
    setSheetName(sheet.name || "");
  }, [sheet.name]);

  const isSheetValid = useMemo(() => {
    return (
      !!sheetName.trim() &&
      sheetName.length <= 31 &&
      sheetName?.toLowerCase() !== EMPTY_SHEET.name.toLowerCase() &&
      !sheets.some((sheet) => sheet.name.toLowerCase() === sheetName.toLowerCase())
    );
  }, [sheetName, sheets]);

  const canSubmit = isSheetValid;

  return (
    <Modal
      contentProps={{ css: tw`max-w-[450px]` }}
      title="Edit sheet dialog"
      header="Edit"
      body={
        <div className="flex flex-col px-6">
          <label className="text-sm">
            Name <span className="text-xxs text-slate-500">({sheetName.length}/31 characters)</span>
          </label>
          <Input
            placeholder="Sheet name..."
            value={sheetName}
            onChange={(e) => {
              if (sheetName.length > 31 && e.target.value.length < sheetName.length) {
                setSheetName(e.target.value);
              } else if (e.target.value.length <= 31) {
                setSheetName(e.target.value);
              }
            }}
          />
        </div>
      }
      footer={
        <Button
          disabled={!canSubmit}
          onClick={() => {
            if (!canSubmit) return;
            updateSheet(sheet.id, { name: sheetName });
            props.onOpenChange?.(false);
          }}
          size="md"
          variant="primary"
        >
          Save
        </Button>
      }
      {...props}
    />
  );
};

export default EditSheetModal;
