import {
  deleteInternalContractMilestone,
  updateInternalContractDetails,
  updateInternalContractMilestone,
} from "api/api";
import CInputWithLabel from "components/custom/CInputWithLabel";
import CSmButton from "components/custom/CSmButton";
import { Button } from "components/editor/components";
import { Modal } from "components/organisms/modal";
import { useEffect, useState } from "react";
import tw from "twin.macro";

const EditDateModal = ({
  isOpen,
  setIsOpen,
  openedMilestone,
  setOpenedMilestone,
  internalContractDetails,
  setInternalContractDetails,
  setSelectedMilestone,
  selectedMilestone,
  milestoneType,
  setMilestoneType,
}) => {
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [details, setDetails] = useState({
    title: "",
    date: "",
  });

  const formatDate = (sDate = new Date()) => {
    return new Date(sDate).toISOString().substring(0, 10);
  };

  useEffect(() => {
    let cOpenedMilestone;
    if (openedMilestone) {
      cOpenedMilestone = JSON.parse(JSON.stringify(openedMilestone));
    }

    let title =
      milestoneType === "milestone" ? cOpenedMilestone?.name : milestoneType === "start" ? "Start Date" : "End Date";

    let date = formatDate(
      milestoneType === "milestone"
        ? cOpenedMilestone?.deadline
        : milestoneType === "start"
        ? internalContractDetails?.internal_contract?.start_date
        : internalContractDetails?.internal_contract?.response_date
    );

    setDetails((pS) => ({
      ...pS,
      title,
      date,
    }));
  }, [openedMilestone, milestoneType, internalContractDetails]);

  const handleDeleteMilestone = async () => {
    if (!openedMilestone || deleting) return;
    setDeleting(true);
    try {
      await deleteInternalContractMilestone(openedMilestone.id);

      setInternalContractDetails((pS) => ({
        ...pS,
        milestones: pS.milestones.filter((ms) => ms.id !== openedMilestone.id),
      }));

      setSelectedMilestone();
      setOpenedMilestone();

      setIsOpen(false);
    } catch (err) {
      console.log(err);
    } finally {
      setDeleting(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (milestoneType === "milestone") updateMilestoneNow();
    else updateInternalContractDetailsNow();
    // else
  };

  const updateMilestoneNow = async () => {
    try {
      const response = await updateInternalContractMilestone(openedMilestone.id, {
        name: details.title,
        deadline: details.date,
      });
      if (response.status === 200) {
        const index = internalContractDetails.milestones.findIndex((ms) => ms.id === openedMilestone.id);
        internalContractDetails.milestones[index] = {
          ...internalContractDetails.milestones[index],
          name: details.title,
          deadline: details.date,
        };
        setInternalContractDetails({ ...internalContractDetails });
        setOpenedMilestone();
        setSelectedMilestone();
        setIsOpen(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateInternalContractDetailsNow = async () => {
    try {
      let data = {};
      if (milestoneType === "start") data.start_date = details.date;
      else data.response_date = details.date;

      const response = await updateInternalContractDetails(internalContractDetails.internal_contract.id, data);
      if (response.status === 200) {
        internalContractDetails.internal_contract[milestoneType === "start" ? "start_date" : "response_date"] =
          details.date;
        setInternalContractDetails({ ...internalContractDetails });

        setOpenedMilestone();
        setSelectedMilestone();
        setIsOpen(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setDetails((pS) => ({
      ...pS,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setMilestoneType("");
    setOpenedMilestone();
    setSelectedMilestone();
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onOpenChange={(o) => {
        if (!o) handleClose();
        setIsOpen(o);
      }}
      contentProps={{ css: tw`w-[550px]` }}
      title="Edit date dialog"
      header="Edit"
      body={
        <form onSubmit={submitHandler} className="mt-5 px-[24px]">
          <CInputWithLabel
            label="Milestone Title"
            type="text"
            placeholder="Enter Title"
            name="title"
            value={details.title}
            disabled={milestoneType !== "milestone"}
            onChange={changeHandler}
          />
          <CInputWithLabel
            label="Date"
            type="date"
            placeholder="Date"
            name="date"
            value={details.date}
            className="mt-3"
            onChange={changeHandler}
          />

          <CSmButton
            title="Delete Milestone"
            disabled={milestoneType !== "milestone"}
            className="bg-[#FF6464] text-[#fff] mt-5 text-[14px]"
            type="button"
            id="milestoneDeleteBtn"
            onClick={handleDeleteMilestone}
            loading={deleting}
            style={milestoneType === "milestone" ? { border: "none", ...styles.deleteBtn } : { ...styles.deleteBtn }}
          />

          <div className="w-full flex justify-end mt-8">
            <div className="flex gap-[4px]">
              <Button
                size="md"
                className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
                variant="outline"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button size="md" variant="primary" type="submit" loading={loading}>
                Save
              </Button>
            </div>
          </div>
        </form>
      }
    />
  );
};

const styles = {
  submitBtn: { width: "66px", display: "flex", justifyContent: "center", height: "40px", alignItems: "center" },
  cancelBtn: { width: "80px", display: "flex", justifyContent: "center", height: "40px", alignItems: "center" },
  deleteBtn: { width: "146px", display: "flex", justifyContent: "center", height: "40px", alignItems: "center" },
};

export default EditDateModal;
