import { BoldIcon } from "../icons/Bold";
import { Button } from "../primitives/Button";
import { Editor } from "@tiptap/react";
import { ItalicIcon } from "../icons";
import { ReactComponent as UnderlineIcon } from "../../editor/icons/underline.svg";
import { StrikethroughIcon } from "../icons/Strikethrough";
import { ToolbarFontSize } from "./ToolbarFontSize";
import { ToolbarFontFamily } from "./ToolbarFontFamily";
import { MessageSquare } from "lucide-react";
import { useCallback } from "react";
import styles from "./Toolbar.module.css";
import { useFlags } from "hook/useFlags";

export function ToolbarInline({ editor }: { editor: Editor }) {
  const flags = useFlags();
  const { comments: shouldEnableComments } = flags;
  const handleUnderline = useCallback(() => {
    editor.chain().focus().toggleUnderline().run();
  }, [editor]);

  return (
    <>
      <Button
        variant="subtle"
        className={`${styles.toolbarButton} `}
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        data-active={editor.isActive("bold") ? "is-active" : undefined}
        aria-label="Bold"
      >
        <BoldIcon />
      </Button>

      <Button
        variant="subtle"
        className={`${styles.toolbarButton} `}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        data-active={editor.isActive("italic") ? "is-active" : undefined}
        aria-label="Italic"
      >
        <ItalicIcon />
      </Button>

      <Button
        variant="subtle"
        className={styles.toolbarButton}
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        data-active={editor.isActive("strike") ? "is-active" : undefined}
        aria-label="Strikethrough"
      >
        <StrikethroughIcon />
      </Button>
      <Button
        variant="subtle"
        className={`${styles.toolbarButton} `}
        onClick={handleUnderline}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
        data-active={editor.isActive("underline") ? "is-active" : undefined}
        aria-label="underline"
      >
        <UnderlineIcon />
      </Button>
      {shouldEnableComments && (
        <Button
          variant="subtle"
          className={`${styles.toolbarButton} `}
          onClick={() => editor.chain().focus().setActiveComment().setDraft().run()}
          disabled={!editor.can().chain().focus().setDraft().run()}
          aria-label="Comment"
        >
          <MessageSquare size={14} />
        </Button>
      )}
      <ToolbarFontSize editor={editor} />
      <ToolbarFontFamily editor={editor} />
    </>
  );
}
