/** @jsxImportSource @emotion/react */

import classNames from "classnames";
import { useSelection } from "components/copilot/extract-v2/doc-viewer/document-display/SelectionContext";
import { pluralizeWord } from "utils/string";

type Props = {
  /**
   * Function to handle click events on the "Insert Here" button.
   * Triggers insertion of selected requirements at the specified spot.
   */
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => unknown;
  /**
   * If `true`, activates the insert button only on hover, making the button
   * visible when hovered over but hidden otherwise. Allows more subtle UI interactions.
   */
  onHoverOnly?: boolean;
  /**
   * Label to display for the items to be inserted (e.g., "Requirement").
   * Defaults to "Requirement" but can be customized.
   */
  insertLabel?: string;
};

/**
 * InsertHereLine is a UI component that displays a clickable text button with
 * a dashed horizontal line, typically used as a drop-in placeholder to insert
 * requirements at specific spots in the UI. When requirements are selected, the
 * button shows the number of items to be inserted and updates the label
 * dynamically. Supports optional hover-only activation for flexible interaction
 * based on context.
 */
export default function InsertHereLine({ onClick, onHoverOnly, insertLabel = "Requirement" }: Props) {
  const { selectedBlocks, clearSelection } = useSelection();

  if (!selectedBlocks?.length) {
    return <></>;
  }

  const parent =
    "z-[4] justify-self-center w-fit peer/middle flex pt-1.5 justify-center absolute left-0 right-0 top-full";
  const parentHoverClass =
    "peer-hover:opacity-100 hover:opacity-100 opacity-0 pointer-events-none peer-hover:pointer-events-auto hover:pointer-events-auto hover:z-[4] peer-hover:z-[4]";
  const bar = "z-[3] flex justify-center absolute left-0 right-0 top-full border-t-4 border-dashed border-blue-400";
  const barHoverClass =
    "peer-hover:opacity-100 peer-hover/middle:opacity-100 opacity-0 pointer-events-none peer-hover:pointer-events-auto peer-hover/middle:pointer-events-auto";

  return (
    <>
      <div className={classNames(parent, onHoverOnly && parentHoverClass)}>
        <button
          onClick={(e) => {
            onClick(e);
            clearSelection?.();
          }}
          className="bg-blue-400 text-white text-xs rounded-md px-2 py-1 shadow-sharp-thin hover:bg-blue-500"
        >
          Insert {selectedBlocks.length} {pluralizeWord(selectedBlocks.length, insertLabel)} Here +
        </button>
      </div>
      <button
        onClick={(e) => {
          onClick(e);
          clearSelection?.();
        }}
        className={classNames(bar, onHoverOnly && barHoverClass)}
      />
    </>
  );
}
