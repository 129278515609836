/** @jsxImportSource @emotion/react */
import { Button } from "components/editor/components";
import { Modal } from "components/organisms/modal";
import { useCallback, useEffect, useState } from "react";
import IconButton from "components/atoms/icon-button/IconButton";
import { Template, TemplateDetails } from "types/Templates";
import { useTemplateDetails, useTemplates } from "./hooks";
import tw from "twin.macro";
import { transformImmutableVolumesToYJS } from "./utils";
import { deleteTemplate, getTemplateDetails, updateTemplateDetails } from "api/api";
import { useHandleApiError } from "hook/useHandleApiError";
import { getMessageFromAxiosError } from "utils/getMessageFromAxiosError";
import { setTemplates } from "store/reducers/templates/templatesReducer";
import { useAppDispatch } from "store/storeTypes";
import { Storage as ImmutableStorage } from "../CopilotSchemaImmutableTypes";
import { toggleTemplatesModal } from "store/reducers/modalsSlice";
import { Framework, Storage } from "../CopilotSchemaTypes";
import { AxiosResponse } from "axios";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { useMutation, useStorage } from "YJSProvider/createYJSContext";
import { LiveObject } from "YJSProvider/LiveObjects";

export type TemplatesModalProps = {
  open: boolean;
};

const TemplatesModal = ({ open }: TemplatesModalProps) => {
  const frameworkState = useStorage((root) => root.framework as ImmutableStorage["framework"]);
  const dispatch = useAppDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [isEditing, setIsEditing] = useState<Template | undefined>();
  const [isSettingTemplate, setIsSettingTemplate] = useState<boolean>(false);

  // @ts-ignore
  const templateInUse = frameworkState?.template?.id;
  const { setError } = useHandleApiError();
  const { templates, getTemplates } = useTemplates();
  const { fetchTemplateDetails } = useTemplateDetails();

  const handleEditTemplate = useCallback(
    async (payload: Partial<TemplateDetails>) => {
      if (!isEditing?.id) return;
      let prevstate = templates;

      try {
        dispatch(
          setTemplates(
            templates.map((template) =>
              template.id === isEditing.id ? { ...template, ...(payload.name && { name: payload.name }) } : template
            )
          )
        );

        setIsEditing(undefined);
        await updateTemplateDetails(isEditing.id, payload);
        await getTemplates();
        if (templateInUse === isEditing.id) fetchTemplateDetails(isEditing.id);
      } catch (err: any) {
        if (prevstate) dispatch(setTemplates(prevstate));
        if (err.response?.data?.name) {
          setError(getMessageFromAxiosError(err.response?.data));
        } else {
          setError("Failed to update template. Please refresh and try again.");
        }
      }
    },
    [dispatch, fetchTemplateDetails, getTemplates, isEditing?.id, setError, templateInUse, templates]
  );

  const onSelectTemplate = useMutation(
    async ({ storage }) => {
      if (isSettingTemplate) return;
      setIsSettingTemplate(true);

      try {
        const framework = storage?.get("framework") as Storage["framework"];
        const liveVolumes = framework?.get("volumes") as Framework["volumes"];
        const { data }: AxiosResponse<TemplateDetails> = await getTemplateDetails(selectedTemplate?.id);

        if (data) {
          const transformedLiveVolumes = transformImmutableVolumesToYJS(data.data?.volumes || []);
          transformedLiveVolumes?.forEach((vol) => liveVolumes?.push([vol]));
          framework?.set("template", new LiveObject({ id: selectedTemplate?.id, isDirty: true }));
        } else throw new Error();
      } catch (err) {
        console.log(err);
        setError("Failed to import template. Please refresh and try again.");
      } finally {
        dispatch(toggleTemplatesModal({ open: false }));
        setSelectedTemplate(null);
        setIsSettingTemplate(false);
      }
    },
    [selectedTemplate]
  );

  const onDeleteTemplate = useMutation(
    async ({ storage }, templateId?: string) => {
      if (!templateId) return;

      try {
        await deleteTemplate(templateId);
        // @ts-ignore
        if (templateInUse === templateId) storage?.get("framework")?.get("template")?.set("id", "");
        await getTemplates();
      } catch (err) {
        setError("Failed to delete template. Please refresh and try again.");
      } finally {
        dispatch(toggleTemplatesModal({ open: true }));
      }
    },
    [templateInUse]
  );

  useEffect(() => {
    if (open) {
      getTemplates();
    }
  }, [getTemplates, open]);

  return (
    <>
      <Modal
        open={open}
        onOpenChange={(o) => {
          dispatch(toggleTemplatesModal({ open: o }));
          if (!o) {
            setSelectedTemplate(null);
            setIsEditing(undefined);
          }
        }}
        contentProps={{
          onOpenAutoFocus: (e) => e.preventDefault(),
          onCloseAutoFocus: (e) => e.preventDefault(),
          onEscapeKeyDown: (e) => e.preventDefault(),
        }}
        title="Templates dialog"
        header="Templates"
        body={
          <div className="w-[460px] flex-col max-h-[300px] overflow-y-auto px-2">
            {!!templates?.length &&
              templates.map(
                (template, idx) =>
                  (
                    <div key={template.id}>
                      <button
                        key={template.id}
                        className="p-4 w-full duration-100 rounded-sm group hover:bg-gray-100"
                        css={[selectedTemplate?.id === template.id && tw`bg-gray-100`]}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedTemplate(selectedTemplate?.id === template.id ? null : template);

                          if (isEditing) setIsEditing(undefined);
                        }}
                        onBlur={(e) => {
                          if (e.currentTarget.contains(e.relatedTarget)) return;

                          if (isEditing) {
                            handleEditTemplate({ name: isEditing.name });
                          }
                        }}
                      >
                        <div className="flex items-center justify-between gap-4 text-left">
                          {!!isEditing?.id && isEditing.id === template.id ? (
                            <input
                              autoFocus
                              className="w-full bg-transparent outline-0 text-stone-900 text-sm font-normal"
                              value={isEditing.name}
                              onChange={(e) => setIsEditing((prev) => prev && { ...prev, name: e.target.value })}
                              onClick={(e) => e.stopPropagation()}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleEditTemplate({ name: isEditing.name });
                                }
                              }}
                              onKeyUp={(e) => {
                                if (e.code === "Space") e.preventDefault();
                              }}
                            />
                          ) : (
                            <span className="text-stone-900 text-sm font-normal break-all">{template.name}</span>
                          )}
                          <div
                            className="flex items-center gap-2 opacity-0 duration-100 group-hover:opacity-100"
                            css={[isEditing?.id === template.id && tw`opacity-100`]}
                          >
                            {isEditing?.id === template.id ? (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditTemplate({ name: isEditing.name });
                                }}
                                name="Check"
                                className="text-green-600 bg-zinc-100 p-1 duration-150 rounded-md hover:bg-zinc-200"
                                css={[isEditing]}
                              />
                            ) : (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsEditing({ ...template });
                                }}
                                name="Pencil"
                                className="text-zinc-400 p-1 duration-150 rounded-md hover:bg-zinc-200"
                              />
                            )}
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();

                                triggerConfirm({
                                  proceedLabel: "Delete",
                                  header: `Are you sure you want to delete "${template?.name}" Template?`,
                                  body: "This is an irreversible operation.",
                                }).then((proceed) => {
                                  if (proceed) {
                                    onDeleteTemplate(template?.id);
                                  }
                                });
                                dispatch(toggleTemplatesModal({ open: false }));
                                if (isEditing) setIsEditing(undefined);
                              }}
                              name="Trash"
                              className="text-red-500 p-1 duration-150 rounded-md hover:bg-red-100"
                            />
                          </div>
                        </div>
                      </button>
                      {templates.length - 1 !== idx && <hr className="border-gray-200" />}
                    </div>
                  ) || (
                    <span className="w-full h-full flex justify-center items-center text-gray-600 italic">
                      No Templates
                    </span>
                  )
              )}
          </div>
        }
        footer={
          <>
            <Button
              size="md"
              variant="outline"
              className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(toggleTemplatesModal({ open: false }));
                setSelectedTemplate(null);
                setIsEditing(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              loading={isSettingTemplate}
              disabled={!selectedTemplate}
              size="md"
              variant="primary"
              css={[isSettingTemplate && tw`pointer-events-none`]}
              onClick={(e) => {
                e.stopPropagation();
                if (!selectedTemplate || isSettingTemplate) return;

                onSelectTemplate();
              }}
            >
              Import
            </Button>
          </>
        }
      />
    </>
  );
};

export default TemplatesModal;
