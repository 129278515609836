import { jwtDecode } from "jwt-decode";

export const REFRESH_TIME = 5 * 60 * 1000; // 5 minutes

// Function to set the refresh timer in localStorage
export const setSessionRefreshTimer = () => {
  const refreshTime = Date.now() + REFRESH_TIME;
  localStorage.setItem("vultron_refresh_timer", refreshTime.toString());
};

export const getSessionRefreshTimer = () => {
  const timer = localStorage.getItem("vultron_refresh_timer");
  return timer ? parseInt(timer, 10) : null;
};

export const isSessionRefreshRequired = () => {
  // Check if the refresh timer is set and not expired
  const timer = getSessionRefreshTimer();
  if (timer && Date.now() < timer) return false;

  // Set the refresh timer to avoid multiple requests refreshing
  setSessionRefreshTimer();
  return true;
};

export const isJWTExpired = (token: string) => {
  if (!token) return true;
  try {
    const decoded: any = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    return true;
  }
};
